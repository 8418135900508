<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="header">

              <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
                <div :class="{ 'show': showContent }" class="header__section-info">
                  <div class="title">Diesel Generators</div>
                </div>
                <transition name="fade">
                  <div v-if="showImage" class="header__section-img"></div>
                </transition>
              </div>
            </div>
            <div class="description" ref="description">
              Diesel electric generators are machines that produce electricity by burning diesel fuel. These practical
              and efficient machines use a combination of a diesel engine and an electric generator to generate
              electricity. It converts the chemical energy contained in diesel fuel into mechanical energy through
              combustion.
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img2"></div>
          <div class="img1"></div>
        </div>

        <div class="content">
          <div class="box box__left" :class="{ 'show': showAksa }" id="kohler">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  KOHLER
                </div>
                <div class="container-content__description container-content__description-left">
                  Since day one, Kohler has set the standard in terms of design and technology, forging a global reputation
                  for top-end products. Today, they are one of the largest manufacturers of generators worldwide, with
                  production plants located on four continents and extensive global sales, service and distribution
                  networks. With their generator sets, ranging from 2 to 4,500 kVA, they provide energy solutions for
                  different applications (continuous and backup production) and for all sectors: data centers, health, water
                  treatment, telecommunications, construction, energy production, industry, transport, retail trade and
                  distribution, etc.
                </div>
                <div class="container-content__pdfs">
                  <a href="/pdfs/elektroagregati/kohler.pdf" target="_blank" class="btn">PDF Catalogue 1</a>
                  <a href="/pdfs/elektroagregati/kohler2.pdf" target="_blank" class="btn">PDF Catalogue 2</a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/kohler.png" alt="" loading="lazy">
            </div>


          </div>

          <div class="box box__right" :class="{ 'show': showTeksan }" id="teksan">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  TEKSAN
                </div>
                <div class="container-content__description container-content__description-right">
                  For more than 25 years, thanks to its experience and engineering know-how, Teksan has been providing
                  tailormade power solutions that can efficiently operate even under the most challenging conditions for
                  major international projects such as constructions, telecommunications, data centers, shopping centers,
                  hotels, residential buildings, supermarkets, sport centers, mining facilities, hospitals, research
                  centers, educational institutions and industrial plants all around the world.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="/pdfs/elektroagregati/teksan.pdf" target="_blank" class="btn">PDF Catalogue </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/teksan.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showKohler }" id="aksa">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  AKSA
                </div>
                <div class="container-content__description container-content__description-left">
                  Since its foundation in 1984, being the leading company in the Turkish market; Aksa Power Generation takes
                  place among the top 200 industrial enterprises and exporter firms in Turkey.
                  <br>
                  In addition, Aksa is rightfully proud of being one of the leading generating sets manufacturers in the
                  world with the total amount of 40.000 gasoline, diesel and natural gas generating sets ranging from 1 to
                  3125 kVA manufactured per year in its four production facilities, one of in Cerkezkoy - Turkey, Changzhou
                  - China, Louisiana - The U.S.A. and a trade center in Rotterdam - The Netherlands.
                </div>
                <div class="container-content__pdfs">
                  <a href="/pdfs/elektroagregati/aksa.pdf" target="_blank" class="btn">PDF Catalogue</a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/aksa.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="marquee">
            <div class="marquee-content">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'ElektroAgregati',
  components: {
    FooterSection,
    Header
  },
  data() {
    return {
      showImage: false,
      showContent: false,
      showAksa: false,
      showTeksan: false,
      showKohler: false,

      images: [
        require('@/assets/images/top-bg.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/interior-large-logistics-warehouse-ai-generative.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/bottom-bg.png'),
      ],
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ],
    };
  },
  mounted() {
    this.showImage = true;

    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.description.classList.add('fade-in');
      });
    }, 500);
    setTimeout(() => {
      this.showContent = true;
    }, 500);

    setTimeout(() => {
      this.showAksa = true;
    }, 1100);
    setTimeout(() => {
      this.showTeksan = true;
    }, 1700);
    setTimeout(() => {
      this.showKohler = true;
    }, 2600);
  },

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

/*.background-image {
  flex: 1;
  background-size: cover;
  background-position: center;
  min-height: 20vh;
  position: relative;
}

.background-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(173, 156, 156, 0.5);
}*/

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 1050px;

      @include respond(phone) {
        height: 850px;
      }
      @include respond(lil-phone) {
        height: 900px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.body-section {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 900px;
      /*   @include respond(from-phone-to-tab) {
           height: 650px;
         }*/
      @include respond(lil-phone) {
        height: 1000px !important;
      }
      @include respond(phone) {
        height: 750px;
      }
    }

    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 1050px;
      @include respond(from-phone-to-tab) {
        height: 1400px;
      }

      @include respond(tab-land) {
        height: 1200px;
      }
      @media only screen and (min-width: 1201px) and (max-width: 1499px) {
        height: 1200px;
      }
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 830px;
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

    }
  }

  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    @include respond(phone) {
      margin-top: 0;
    }
  }

  .marquee-content {
    display: flex;
    width: calc(100% * 2); /* Double the container size for duplication */
    animation: marquee 20s linear infinite;
  }

  .logo {
    max-width: 150px;
    height: 125px; /* Set a fixed height */
    flex-shrink: 0;
    margin-right: 50px; /* Space between logos */
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    display: flex;

    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      //   align-items: flex-start;
      align-items: center;
      margin-top: 40px;
      @include respond(phone) {
        flex-direction: column;
        margin-top: 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;

        transform: translateY(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 2s ease-out;
        @include respond(phone) {
          text-align: center;
        }

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .title {
          font-size: 100px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          text-align: center;
          @include respond(phone) {
            font-size: 30px !important;
            width: 100%;
          }
        }

      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;
      }

      &-img {
        background-image: url('~@/assets/images/naslovna-elektroagregati.png');
        border-radius: 10px;
        width: 500px;
        height: 450px;
        background-size: cover;
        @include respond(phone) {
          display: block !important;
          width: 90vw;
          height: 200px;
          border-radius: 8px;
        }
      }
    }

    .title {
      font-size: 50px;
      font-family: "Montserrat";
      color: $primary-blue;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      @include respond(from-phone-to-tab) {
        font-size: 40px;
      }
    }
  }

  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 40px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .background-image {
    min-height: 40vh;
  }
}
</style>
