<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="description" ref="description">
              SERVICE AND SUPPORT - Regular servicing is essential to ensure that generators
              remain reliable power sources at all times. This requires ensuring trained and skilled personnel, a
              stock of spare parts, as well as tools and equipment for servicing.
              <br>
              In addition to reliability, regular servicing is necessary for the validity of the warranty issued by
              the manufacturer.
              <br> <br>
              In the event of signing a contract with NOVI SJEVER MONT doo, it is possible to arrange an extension of
              the warranty period, as well as emergency response within a few hours 365/7/24.
              <br>
              We also offer servicing for other manufacturers and brands.

            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img2"></div>
        </div>

        <div class="content">
          <div class="wrapper">
            <div class="header">

              <div class="catalog-images">
                <ul class="grid-custom" ref="imagesList">
                  <transition-group
                      appear
                      name="list"
                  >
                    <li v-for="(icon, index) in images" :key="icon.name" :data-index="index" class="list-item-hidden">
                      <div class="service">
                        <img :src="icon.name" alt="" loading="lazy">
                        <div class="title">
                          <h1>{{ icon.text }}</h1>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import {onMounted, ref} from "vue";
import gsap from "gsap";

export default {
  name: 'ServicesPage',
  components: {
    FooterSection,
    Header
  },
  setup() {
    const images = ref([
      {name: './services/sales.png', text: 'Sales'},
      {name: './services/installation.png', text: 'Installation '},
      {name: './services/servicing.png', text: 'Servicing '},
      {name: './services/maintenance.png', text: 'Maintenance '},
      {name: './services/analysis.png', text: 'Fault Analysis'},
      {name: './services/spare-parts.png', text: 'Availability of Spare Parts'},
      {name: './services/consulting.png', text: 'Consulting '},
      {name: './services/documents.png', text: 'Project Documentation Development'}
    ]);
    const imagesList = ref(null);

    onMounted(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.querySelectorAll('li').forEach((el, index) => {
              el.classList.remove('list-item-hidden');
              gsap.fromTo(el, {
                opacity: 0,
                translateY: 100,
                visibility: 'hidden'
              }, {
                opacity: 1,
                translateY: 0,
                visibility: 'visible',
                duration: 1.2,
                delay: index * 0.2
              });

            });
            observer.unobserve(entry.target);
          }
        });
      }, {
        threshold: 0.5
      });

      observer.observe(imagesList.value);
    });

    return {images, imagesList};
  },
  data() {
    return {
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
mounted() {
  setTimeout(() => {
    this.$refs.description.classList.add('fade-in');
  }, 500);
}

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 800px;
      @include respond(from-phone-to-tab) {
        height: 850px;
      }
      @include respond(phone) {
        height: 850px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;

      .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

      }
    }
  }
}

.body-section {
  .cover-img {
    .img {
      .img2 {
        height: 610px;
        @include respond(phone) {
          height: 1050px !important;
        }
      }
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    .body {
      .description {
        max-width: 70vw;
        text-align: center;
        color: $primary-blue;
        font-size: 22px;
        margin-top: 80px;
        font-family: "Karla";
        opacity: 0;
        transition: opacity 3s ease-out;
        @include respond(phone) {
          text-align: justify;
          margin-top: 40px;
          font-size: 20px;
          max-width: 95vw;
        }
        @include respond(lil-phone) {
          margin-top: 20px;
        }
      }

      .description.fade-in {
        opacity: 1;
      }
    }

    .catalog-images {
      margin-top: 100px;
      @include respond(from-phone-to-tab) {
        margin-left: -30px;
      }
      @include respond(phone) {
        margin-left: -30px;
      }
      .service {
        width: 240px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.87);
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        transition: transform 0.7s ease-out, color 1s ease;

        @include respond(phone) {
          width: 200px;
          height: 170px;

        }


        &:hover {
          transform: scale(1.1);
        }
      }

      img {
        padding: 20px;
        width: 95px;
/*        @include respond(from-phone-to-tab) {
          max-width: 250px;
        }
        @include respond(phone) {
          max-width: 200px;
          height: 150px;
        }*/
      }

      h1 {
        color: $primary-blue;
        font-size: 30px;
        font-weight: 400;
        line-height: 1;
        padding-right: 10px;
        @include respond(from-phone-to-tab) {
          font-size: 30px;
        }
        @include respond(phone) {
          font-size: 20px;
        }
      }

      .title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      li {
        list-style-type: none;
        border-radius: 10px;
        cursor: pointer;
        line-height: 1.5em;
      }

      .grid-custom {
        display: grid !important;
        grid-template-columns: repeat(4, 1fr) !important;
        //grid-template-columns: 1fr 1fr !important;
        grid-gap: 20px !important;
        margin: 40px auto !important;
        @include respond(phone) {
          grid-gap: 10px !important;
          grid-template-columns: repeat(2, 1fr) !important;
        }
      }
    }

  }
  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 40px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 630px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .background-image {
    min-height: 40vh;
  }
}

</style>
