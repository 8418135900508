<template>
  <div class="wrapper">
    <div class="header">
      <div class="header__logo-section">
        <div class="info">
          <a href="mailto:office@novisjevermont.me">
            <div class="info-item">office@novisjevermont.me</div>
          </a>
          <div class="info-item address">
            <img src="~@/assets/images/location.png" alt="">
            <a href="https://maps.app.goo.gl/VVYb5L3Hm26Zqdkf7">
              <div class="text">
                Dr Vukašina Markovića 218, broj 1 <br>
                Podgorica, Crna Gora
              </div>
            </a>
          </div>
        </div>
        <div class="logo" @click="navigateTo('/#')">
          <img src="../assets/images/logo.png" alt="">
        </div>
        <div class="info">
          <div class="info-item address social">
            <a href="https://www.instagram.com/novi_sjever_mont/" class="insta-link">
              <img src="~@/assets/images/instagram.png" alt="" class="insta-image">
            </a>
            <a href="https://www.facebook.com/Novi-Sjever-Mont-100834288546477" class="fb-link">
              <img src="~@/assets/images/facebook.png" alt="" class="fb-image">
            </a>
            <a href="mailto:office@novisjevermont.me" class="email-link">
              <img src="~@/assets/images/email.png" alt="" class="email-image">
            </a>
          </div>
          <a class="info-item number" href="tel:+38268619444">(+382) 68 619 444</a>
          <a class="info-item number" href="tel:+38267679314">(+382) 67 679 314</a>
        </div>
      </div>
      <div class="header__logo-section-mobile">
        <div class="d-flex-custom ">
          <div class="logo" @click="navigateTo('/#')">
            <img src="../assets/images/logo.png" alt="" loading="lazy">
            <div class="info-item address info-item-social">
              <a href="https://www.instagram.com/novi_sjever_mont/" class="insta-link">
                <img src="~@/assets/images/instagram.png" alt="" class="insta-image">
              </a>
              <a href="https://www.facebook.com/Novi-Sjever-Mont-100834288546477" class="fb-link">
                <img src="~@/assets/images/facebook.png" alt="" class="fb-image">
              </a>
              <a href="mailto:office@novisjevermont.me" class="email-link">
                <img src="~@/assets/images/email.png" alt="" class="email-image">
              </a>
            </div>
          </div>
          <div class="info">
            <a href="mailto:office@novisjevermont.me">
              <div class="info-item">office@novisjevermont.me</div>
            </a>
            <div class="info-item address">
              <img src="~@/assets/images/location.png" alt="">
              <a href="mailto:office@novisjevermont.me">
                <div class="text">
                  Dr Vukašina Markovića 218, broj 1 <br>
                  Podgorica, Crna Gora
                </div>
              </a>
            </div>
            <div class="info-item info-item-number">
              <a class="number" href="tel:+38268619444">(+382) 68 619 444</a> /
              <a class="number" href="tel:+38267679314">67 679 314</a>
            </div>

          </div>

        </div>
      </div>
      <div class="header__menu">
        <div class="header__menu-item" @click="navigateTo('/about-us')">About Us</div>
        <div class="header__menu-item">
          Products
          <div class="dropdown-content">
            <div class="dropdown-item has-submenu">
              <span>Aviation Equipment </span> <!-- @click="navigateTo('/avio-oprema-aviogei')"-->
              <div class="submenu">
                <div class="dropdown-item" @click="navigateTo('/avio-oprema-aviogei')">AVIOGEI</div>
                <div class="dropdown-item" @click="navigateTo('/avio-oprema-mulag')">MULAG</div>
                <div class="dropdown-item" @click="navigateToName('/avio-oprema-bertoli')">BERTOLI</div>
                <div class="dropdown-item" @click="navigateTo('/avio-oprema-bliss-fox')">BLISS FOX</div>
                <div class="dropdown-item" @click="navigateTo('/avio-oprema-boschung')">BOSCHUNG</div>
              </div>
            </div>
            <div class="dropdown-item has-submenu">
              <span>Road Maintenance Equipment</span> <!-- @click="navigateTo('/saobracajnica-boschung')"-->
              <div class="submenu">
                <div class="dropdown-item" @click="navigateTo('/saobracajnica-boschung')">BOSCHUNG</div>
                <div class="dropdown-item" @click="navigateTo('/saobracajnica-mulag')">MULAG</div>
              </div>
            </div>
            <div class="dropdown-item" @click="navigateTo('/zeljeznica')">Railway Maintenance Equipment</div>
            <div class="dropdown-item has-submenu">
             <span @click="navigateTo('/elektro-agregati')">Diesel Generators</span>
              <div class="submenu">
                <div class="dropdown-item" @click="navigateTo('/elektro-agregati', '#kohler')">KOHLER</div>
                <div class="dropdown-item" @click="navigateTo('/elektro-agregati', '#teksan')">TEKSAN</div>
                <div class="dropdown-item" @click="navigateTo('/elektro-agregati', '#aksa')">AKSA</div>
              </div>
            </div>
            <div class="dropdown-item" @click="navigateTo('/ups-ovi')">UPS Systems</div>
            <div class="dropdown-item" @click="navigateTo('/liftovi-za-osi')">Mobility Lifts</div>

          </div>
        </div>
        <div class="header__menu-item" @click="navigateTo('/services')">services</div>
        <div class="header__menu-item" @click="scrollTo('contact')">Contact</div>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: 'HeaderSection',

  methods: {
    scrollTo(sectionId) {
      const el = document.getElementById(sectionId);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
/*    navigateTo(route) {
      this.$router.push(route);
    }*/
    navigateTo(path, hash = '') {
      this.$router.push({ path: path, hash });
    },
    navigateToName (path) {
      this.$router.push({ path: path });

    }
/*    navigateTo(route, hash = '') {
      console.log(route)
      this.$router.push({ path: route, hash }).catch(err => {
        // handle error if necessary, for example log or ignore navigation duplication
        console.error(err);
      });
      this.$nextTick(() => {
        if (hash && document.querySelector(hash)) {
          window.scrollTo({
            top: document.querySelector(hash).offsetTop,
            behavior: "smooth"
          });
        }
      });
    }*/

  }

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow: visible;

  .header {
    width: 70vw;
    overflow: visible;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__logo-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 60px;

      @include respond(from-phone-to-tab) {
        padding-top: 20px;
      }
      @include respond(phone) {
        display: none;
        flex-direction: column;
        align-items: normal;
        gap: 15px;
        padding-top: 20px;
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;
        cursor: pointer;
        margin-left: 40px;

        @include respond(from-phone-to-tab) {
          align-items: normal;
          margin-left: 0px;
        }
        @include respond(phone) {
          align-items: normal;
          margin-left: 0px;
        }

        &-item {
          background: linear-gradient(75deg, rgba(93, 132, 183, 0.9) 0%, rgb(36, 85, 151) 100%);
          font-size: 20px;
          font-weight: 600;
          border-radius: 8px;
          color: white;
          font-family: "Montserrat";
          letter-spacing: 0.3px;
          padding: 3px 9px;
          transition: color 0.9s ease;

          @include respond(from-phone-to-tab) {
            font-size: 14px;
            border-radius: 4px;
            padding: 3px 7px;
          }

          @include respond(phone) {
            font-size: 14px;
          }

          &:hover {
            color: $primary-blue;
          }

          .number {
            letter-spacing: 0.5px;
            font-size: 22px;
          }
        }

        .address {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          font-size: 16px;
          text-align: right;
          transition: color 0.9s ease;
          color: white;

          @include respond(from-phone-to-tab) {
            gap: 8px;
            font-size: 10px;
          }

          .text {
            color: white;
            transition: color 0.9s ease;

            &:hover {
              color: $primary-blue;
            }
          }

          img {
            width: 16px;
            top: 0;

          }
          .email-link .email-image {
            transition: content 0.9s ease;
          }
          .email-link:hover .email-image {
            content: url("~@/assets/images/email-blue.png");
          }
          .insta-link:hover .insta-image {
            content: url("~@/assets/images/instagram-blue.png");
          }
          .fb-link:hover .fb-image {
            content: url("~@/assets/images/facebook-blue.png");
          }
          &:hover {
            color: $primary-blue;
          }
        }

        .social {
          gap: 20px;
          padding: 6px 15px;
          @include respond(phone) {
            padding: 4px 10px;
          }
          @include respond(from-phone-to-tab) {
            padding: 6px 8px;
          }
        }

        a {
          text-decoration: none;
        }


      }

      .ml-10 {
        margin-left: 40px;
      }

      .logo {
        cursor: pointer;

        img {
          height: 140px;
          @include respond(phone) {
            height: 100px;
          }

          @include respond(from-phone-to-tab) {
            height: 95px;
          }
        }
      }
    }

    &__logo-section-mobile {
      display: none;

      .d-flex-custom {
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
      }

      @include respond(phone) {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-top: 15px;
        width: 100%;

        .info {
          display: flex;
          flex-direction: column;
          gap: 5px;
          cursor: pointer;
          align-items: normal;
          margin-left: 0px;

          &-column {
            display: flex;
            gap: 35px;
            flex-direction: column;

            img {
              width: 20px;
            }
          }

          &-item {
            background: linear-gradient(75deg, rgba(93, 132, 183, 0.9) 0%, rgb(36, 85, 151) 100%);
            font-size: 14px;
            font-weight: 600;
            border-radius: 4px;
            color: white;
            font-family: "Montserrat";
            letter-spacing: 0.3px;
            padding: 10px 9px;
            transition: color 0.9s ease;

            &:hover {
              color: $primary-blue;
            }

            .number {
              letter-spacing: 0.2px;
              font-size: 14px;
            }
            &-number {
              padding: 7px 5px;
            }
            a {
              text-decoration: none;
              color: white;
            }
            &-social {
              display: flex;
              align-items: center;
              gap: 20px;
              padding: 8px 10px;

              img {
                height: 18px !important;
                top: 0 !important;
              }
            }
          }

          .address {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            font-size: 12px;
            text-align: right;
            transition: color 0.9s ease;
            color: white;

            .text {
              color: white;
              transition: color 0.9s ease;

              &:hover {
                color: $primary-blue;
              }
            }

            img {
              width: 16px;
              top: 0;
            }
            .email-link:hover .email-image {
              content: url("~@/assets/images/email-blue.png");
            }
            .insta-link:hover .insta-image {
              content: url("~@/assets/images/instagram-blue.png");
            }
            .fb-link:hover .fb-image {
              content: url("~@/assets/images/facebook-blue.png");
            }
            &:hover {
              color: $primary-blue;
            }
          }


          a {
            text-decoration: none;
          }


        }

        .logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;

          img {
            height: 120px;
          }
        }
      }

    }

    &__menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $primary-blue;
      border-bottom: 2.5px solid rgb(52 105 171);
      padding-top: 60px;
      @include respond(phone) {
        padding-top: 20px;

      }

      &-item {
        font-weight: 650;
        font-size: 22px;
        text-transform: uppercase;
        font-family: 'Montserrat';
        padding: 10px 15px;
        cursor: pointer;
        position: relative;
        width: 100%;
        transition: color 0.3s, text-shadow 0.3s;

        @include respond(phone) {
          font-size: 14px;
          padding: 8px;
          font-weight: 600;
        }
        @include respond(lil-phone) {
          font-size: 12px;
          padding: 8px;
          font-weight: 600;
        }
        @include respond(from-phone-to-tab) {
          font-size: 16px;
        }

        &:hover {
          //  font-size: 23px;
          //  font-weight: 600;
          color: $light-blue;
          text-shadow: 0px 0px 2px rgba(74, 74, 128, 0.5);

          .dropdown-content {
            max-height: 500px;
            opacity: 1;
            transform: translateY(0);
          }
        }
      }


      .dropdown-content {
        font-family: 'Montserrat';
        position: absolute;
        background-color: #25508BD7;
        border-radius: 10px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        left: 0;
        max-height: 0;
        opacity: 0;
        transform: translateY(-10px);
        transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
        @include respond(from-phone-to-tab) {
          left: -140px;
        }
        @include respond(phone) {
          left: -100%;
        }

        .dropdown-item {
          font-size: 18px;
          padding: 12px 10px;
          text-decoration: none;
          text-align: left;
          // display: block;
          color: white;
          transition: transform 0.3s, box-shadow 0.3s;
          transform-origin: center;
          @include respond(phone) {
            font-size: 14px;
            padding: 8px 6px;
          }
          &.has-submenu {
            &:hover {
              .submenu {
                visibility: visible;
                opacity: 1;
                transform: translateX(0);
                transition: opacity 0.5s ease, transform 0.5s ease;
              }
            }
          }

          &:hover {
            // border-bottom: 3.5px solid rgb(255 255 255 / 35%);
            //box-shadow: 0 5px 20px 5px rgb(130, 152, 175);
            box-shadow: 0 -10px 15px -3px rgb(130, 152, 175),
            0 10px 15px -3px rgb(130, 152, 175);
            transform: scale(1.02);
          }

          .submenu {
            display: block;
            visibility: hidden;
            position: absolute;
            left: 100%;
            top: 0;
            background-color: #25508BD7;
            border-radius: 10px;
            min-width: 160px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            opacity: 0;
            transform: translateY(-10px);
            transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
          //  margin-left: 3px;
          }

          /*       &.has-submenu:hover .submenu {
                   visibility: visible;
                   opacity: 1;
                   transform: translateY(0);
                 }*/
        }
      }

      .arrow {
        float: right;
      }
    }

  }
}

</style>
