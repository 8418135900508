<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="header">
              <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
                <div :class="{ 'show': showContent }" class="header__section-info">
                  <div class="title">UPS Systems</div>
                </div>
                <transition name="fade">
                  <div v-if="showImage" class="header__section-img"></div>
                </transition>
              </div>
            </div>
            <div class="description" ref="description">
              <b>Albat Energy Systems:</b> <br>

              Main products, at the beginning, were traction batteries for forklifts and batteries for mains
              locomotives. In very short time, we started producing rectifier for that battery.
              After few years, when the firm started selling products to Serbia and grew up its business, Bosnia and
              Herzegovina was in the war. Because of the war, in 1992, effects and imposibility to work on the main
              Market, company ALBAT and all its activities were moved to Slovakia, City of Košice. But in 2005, company
              ALBAT came back to Bosnia and Herzegovina, City of Sarajevo.
              After about 30 years expirience, ALBAT has leading position on mains Markets, with significant activities
              in the states of Sout-East and Central Europe.
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
        </div>

        <div class="content">
          <div class="box box__left" :class="{ 'show': showLeft}">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  UPS SERIES ATLUS RACK-COM
                </div>
                <div class="container-content__description container-content__description-left">

                  The ATLUS RACK-COM series is the best solution for protecting data centers, IT networks,
                  telecommunications
                  systems, automation control systems and promised afford stable and reliable power supply for the
                  critical
                  load.
                  The ATLUS RACK-COM series is available 10-15-20 kVA/kW models with three phase/single phase input and
                  three phase/single phase output.<br>
                  ALBAT'S UPS series ATLUS represents a perfect solution for a wide range application in the:<br>
                  - Data Center, <br>
                  - Home/Office,<br>
                  - Transport,<br>
                  - Industry,<br>
                  - Emergency

                </div>
                <div class="container-content__pdfs">
                  <a href="/pdfs/ups/UPS-SERIES-ATLUS-RACK-COM-10-20KVA_KW.pdf" target="_blank" class="btn">PDF
                    Catalogue</a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/ups/Atlus-Rack-Com.png" alt="" loading="lazy">

            </div>

          </div>

          <div class="box box__right" :class="{ 'show': showRight }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  UPS SERIES ATLUS SAURUS 60-200 kVA
                </div>
                <div class="container-content__description container-content__description-right">
                  The ALBAT SAURUS UPS series includes double conversion tower UPS with powers from 60kVA up to 200kVA.
                  SAURUS UPSs are available in three sizes: 60kVA, from 80kVA to 120kVA and from 160kVA to 200kVA. The
                  SAURUS three phase UPS series uses a centralized modular design, allowing for future expansion of the
                  UPS.
                  These UPSs can be expanded in power directly on site and from the display.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="/pdfs/ups/UPS-SERIES-SAURUS.pdf" target="_blank" class="btn">PDF Catalogue </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/ups/ATLUS-SAURUS-KVA.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft2 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  UPS SERIES ATLUS SAURUS 10-40 kVA
                </div>
                <div class="container-content__description container-content__description-left">
                  UPS for companies are an essential component for business continuity in any sector. When it comes to
                  three-phase UPS, the range of products available on the market is very vast, and among these the ALBAT
                  SAURUS three-phase UPSs stand out. These double conversion tower UPSs are available in powers ranging
                  from
                  10kVA to 40kVA and are characterized by their high efficiency, thanks to the 3-level IGBT technology
                  integrated in the inverter section.

                </div>
                <div class="container-content__pdfs">
                  <a href="/pdfs/ups/UPS-SERIES-SAURUS-10-40-1.pdf" target="_blank" class="btn">PDF Catalogue </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/ups/Albat-Atlus-Saurus.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight2 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  UPS SERIES SAURUS MOD 25-600kVA
                </div>
                <div class="container-content__description container-content__description-right">
                  The SAURUS MOD series modularized 3-Phase
                  in, 3-Phase out UPS utilizes advanced 3 level inverter tehnology, a more reliable redundancy design
                  from
                  the entire system down to the components, and digital tehnology interconnection. It has the advantages
                  of
                  high efficiency, high power density, easy scaling, scaling on demand, and occupies only a small amount
                  of
                  floor area and provides safe, reliable and clear environmentally friendly power to loads.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="/pdfs/ups/UPS-SERIES-SAURUS-MOD.pdf" target="_blank" class="btn">PDF Catalogue </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/ups/SAURUS.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft3}">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  UPS SERIES ATLUS TOWER 1-10kVA
                </div>
                <div class="container-content__description container-content__description-left">
                  The ATLUS smart high frequency online, Single Phase Online 1-10kVA, UPS uses full digital control
                  technology and the latest high frequency converter technology and has high efficiency, high power factor
                  and other advantages. It has significant energy savings and greatly reduces operation costs. It has
                  integrated functions such as AC regulation, backup power supply, surge protection, and other functions to
                  provide protection to equipment in harsh power grid environments and provide clean, safe, and stable power
                  to loads.

                </div>
                <div class="container-content__pdfs">
                  <a href="/pdfs/ups/UPS-SERIES-ATLUS.pdf" target="_blank" class="btn">PDF Catalogue</a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/ups/altus-tower.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight3 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  UPS SERIES ATLUS RACK-TOWER 1-10kVA
                </div>
                <div class="container-content__description container-content__description-right">
                  The ATLUS smart high frequency online, Single Phase Online 1-10kVA, UPS uses full digital control
                  technology and the latest high frequency converter technology and has high efficiency, high power factor
                  and other advantages. It has significant energy savings and greatly reduces operation costs. It has
                  integrated functions such as AC regulation, backup power supply, surge protection, and other functions to
                  provide protection to equipment in harsh power grid environments and provide clean, safe, and stable power
                  to loads.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="/pdfs/ups/UPS-SERIES-ATLUS-RACK.pdf" target="_blank" class="btn">PDF Catalogue</a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/ups/ATLUS-RACK-TOWER.png" alt="" loading="lazy">
            </div>

         </div>
          <div class="marquee">
            <div class="marquee-content">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'AvioOprema',
  components: {
    FooterSection,
    Header
  },
  data() {
    return {
      showImage: false,
      showContent: false,
      showLeft: false,
      showRight: false,
      showLeft2: false,
      showRight2: false,
      showLeft3: false,
      showRight3: false,
      showLeft4: false,
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  mounted() {
    this.showImage = true;
    setTimeout(() => {
      this.showContent = true;
    }, 500);
    setTimeout(() => {
      this.showLeft = true;
    }, 1000);
    setTimeout(() => {
      this.showRight = true;
    }, 1600);
    setTimeout(() => {
      this.showLeft2 = true;
    }, 2500);
    setTimeout(() => {
      this.showRight2 = true;
    }, 2800);
    setTimeout(() => {
      this.showLeft3 = true;
    }, 3200);
    setTimeout(() => {
      this.showRight3 = true;
    }, 3300);
    setTimeout(() => {
      this.showLeft4 = true;
    }, 3400);
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.description.classList.add('fade-in');
      });
    }, 500);
  },
  methods: {}

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 1100px;
      @include respond(lil-phone) {
        height: 1250px !important;
      }
      @include respond(phone) {
        height: 1130px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.body-section {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 750px;
    }

    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
      //  position: relative;
      width: 100%;
      height: 1180px;
      @include respond(phone) {
        height: 1070px;
      }
      @include respond(lil-phone) {
        height: 1280px;
      }
      @include respond(from-phone-to-tab) {
        height: 1400px;
      }
      @include respond(desktop) {
        height: 1250px;
      }
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 830px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

    }
  }

  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
    @include respond(phone) {
      margin-top: 0;
    }
  }

  .marquee-content {
    display: flex;
    width: calc(100% * 2); /* Double the container size for duplication */
    animation: marquee 20s linear infinite;
  }

  .logo {
    max-width: 150px;
    height: 125px; /* Set a fixed height */
    flex-shrink: 0;
    margin-right: 50px; /* Space between logos */
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      //   align-items: flex-start;
      align-items: center;
      margin-top: 40px;
      @include respond(phone) {
        flex-direction: column;
        margin-top: 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;

        transform: translateY(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 2s ease-out;
        @include respond(phone) {
          text-align: center;
        }

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .title {
          font-size: 100px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          text-align: center;
          @include respond(phone) {
            font-size: 30px !important;
            width: 100%;
          }
        }

      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;

      }

      &-img {
        background-image: url('~@/assets/images/ups/Naslovna-ALBAT-ENERGY.png');
        border-radius: 10px;
        width: 500px;
        height: 180px;
        background-size: cover;
        @include respond(phone) {
          display: block !important;
          width: 90vw;
          height: 150px;
          border-radius: 8px;
        }
      }
    }

    .title {
      font-size: 50px;
      font-family: "Montserrat";
      color: $primary-blue;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      @include respond(from-phone-to-tab) {
        font-size: 40px;
      }
    }
  }

  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 40px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .pdfs {
    max-width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;

    .pdf {
      .btn {
        background-color: $primary-blue;
        color: white;
        padding: 10px 30px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        transition: transform 0.7s ease-out, color 1s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

</style>
