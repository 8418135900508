<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="header">
              <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
                <div :class="{ 'show': showContent }" class="header__section-info">
                  <div class="title">Aviation Equipment <br> <span> bliss fox </span></div>
                </div>
                <transition name="fade">
                  <div v-if="showImage" class="header__section-img"></div>
                </transition>
              </div>
            </div>
            <div class="description" ref="description">
              <b>Bliss-Fox</b>  Panus GSE are the manufactures of a wide range of GSE products and services for aviation ground
              support providers to help them provide an outstanding operational service to their airline customers. We
              have a long history of over 55 years of designing and manufacturing a wide range of push back tractors
              including our A380 capable FOX-70D model. Over the years we have added to our product range in order to
              provide our valued customers with customized solutions from their trusted supplier.

            </div>
            <div class="pdfs">
              <div class="pdf">
                <a href="/pdfs/avio/bliss-fox.pdf" target="_blank" class="btn">PDF CATALOGUE </a>
              </div>
             </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'AvioFox',
  components: {
    FooterSection,
    Header
  },
  data() {
    return {
      showImage: false,
      showContent: false,
      showLeft: false,
      showRight: false,
      showLeft2: false,
      showRight2: false,
      showLeft3: false,
      showRight3: false,
      showLeft4: false,
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  mounted() {
    this.showImage = true;
    setTimeout(() => {
      this.showContent = true;
    }, 500);
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.description.classList.add('fade-in');
      });
    }, 500);
  },
  methods: {}

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 1100px;
      @include respond(phone) {
        height: 1150px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      .pdfs {
        max-width: 70vw;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 20px;

        .pdf {
          .btn {
            background-color: $primary-blue;
            color: white;
            padding: 10px 30px;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: bold;
            transition: transform 0.7s ease-out, color 1s ease;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}


.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      //   align-items: flex-start;
      align-items: center;
      margin-top: 40px;
      @include respond(phone) {
        flex-direction: column;
        margin-top: 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;

        transform: translateY(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 2s ease-out;
        @include respond(phone) {
          text-align: center;
        }

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .title {
          font-size: 100px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          text-align: center;
          @include respond(phone) {
            font-size: 30px !important;
            width: 100%;
          }
          span {
            color: $light-blue;
          }
        }

      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;
      }

      &-img {
        background-image: url('~@/assets/images/bliss-fox-naslovna.png');
        border-radius: 10px;
        width: 500px;
        height: 300px;
        background-size: cover;
        @include respond(phone) {
          display: block !important;
          width: 90vw;
          height: 200px;
          border-radius: 8px;
        }
      }
    }

    .title {
      font-size: 50px;
      font-family: "Montserrat";
      color: $primary-blue;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      @include respond(from-phone-to-tab) {
        font-size: 40px;
      }
    }
  }

  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 40px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

</style>
