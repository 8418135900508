<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="header">
              <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
                <div :class="{ 'show': showContent }" class="header__section-info">
                  <div class="title">Boschung</div>
                </div>
                <transition name="fade">
                  <div v-if="showImage" class="header__section-img"></div>
                </transition>
              </div>
            </div>
            <div class="description" ref="description">
              <b>Boschung</b> offers an unprecedented suite of trans-seasonal equipment that, year after year, continues
              to
              redefine what is possible. Their engineering feats clean the roads and surfaces for the world’s largest
              airports, busiest highways and most visited cities and municipalities across the globe.
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
        </div>

        <div class="content">
          <div class="box box__left" :class="{ 'show': showAksa }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  URBAN SWEEPER S2.0
                </div>
                <div class="container-content__description container-content__description-left">
                  Meet the Urban-Sweeper S2.0, the first fully electric street sweeper releasing
                  0 (zero) emission and engineered by Boschung. A 2m3 sweeper combining ease and suction power with the
                  most progressive technologies of today.
                  <br>
                  The Urban-Sweeper S2.0 was designed with efficiency in mind, providing an exceptionally light weight and
                  narrow width for maximum reach and suction performance. The Urban-Sweeper S2.0 can fit into the narrowest
                  streets while providing the highest suction performance. With a chassis made out of a high resistant
                  metal, the sweeper can hold a total gross weight of 3,500 kg or 4,000kg.
                </div>
                <div class="container-content__pdfs">
                  <a href="/pdfs/saobracajnice/URBAN-SWEEPER-S2.0.pdf" target="_blank" class="btn">PDF Catalogue</a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/urban-sweper.png" alt="" loading="lazy">

            </div>
             </div>

          <div class="box box__right" :class="{ 'show': showTeksan }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  PONY 3.0
                </div>
                <div class="container-content__description container-content__description-right">
                  Strong and yet compact the Pony P3.0 was engineered to be the 100% electric multipurpose carrier of
                  tomorrow releasing 0 (zero) Emission. The Pony’s all electric power-train delivers unparalleled
                  performance in all weather conditions. Equipped with a quadruple motor All-Wheel Drive system powered by
                  the Boschung engineered lithium-ion battery The Pony P3.0 will grant you an entire work shift in just 100
                  minutes of charging time.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="/pdfs/saobracajnice/Pony-P3.0.pdf" target="_blank" class="btn">PDF Catalogue </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/pony.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showKohler }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  JETBROOM SERIES
                </div>
                <div class="container-content__description container-content__description-left">
                  Originally designed in 1985, the Jetbroom is the world-first high-speed
                  snow removal machine intended for fast action on highways and runways.
                  <br>
                  The Boschung engineered and patented chassis has been purposely
                  developed to hold the broom in between the front and rear axle. The new cutting-edge technology is made of
                  two blowing unit systems: one behind the broom and one in front of the broom, the new and patented
                  Quattro-Jet System. While the accelerated rotation of the broom lifts up snow, slush or water, the 4 jet
                  nozzles fixed in the front of the broom blast everything lifted to the side. The second blower unit,
                  placed behind the broom, blows off any leftover leaving behind a
                  perfectly clean, nearly dry surface.
                </div>
                <div class="container-content__pdfs">
                  <a href="/pdfs/saobracajnice/EN-Jetbroom.pdf" target="_blank" class="btn">PDF Catalogue</a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/jetroom.png" alt="" loading="lazy">

            </div>
                 </div>

          <div class="box box__right" :class="{ 'show': showTeksan2 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  DEL-CING
                </div>
                <div class="container-content__description container-content__description-right">
                  Eliminate the danger of ice with world-leading innovation. With over 90 years of experience in Mobile
                  De-Icing and more than 50 years of know-how in Fixed DeIcing, their knowledge in the art of keeping
                  traffic surfaces safe during the cold seasons is at the state of the art level. Progress, innovative
                  manufacturing processes and expertise every step of the way ensuring safety with the highest precision and
                  efficiency in the most sustainable way.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="/pdfs/saobracajnice/EN-De-Icing.pdf" target="_blank" class="btn">PDF Catalogue </a>
                </div>
              </div>
<!--              <img class="box-img box-img&#45;&#45;right" src="~@/assets/images/del-cing.png" alt="">-->
                <img class="box-img box-img--right" src="~@/assets/images/del-cing2.png" alt="" loading="lazy">

            </div>
          </div>

          <div class="marquee">
            <div class="marquee-content">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'BoschungSaobracaj',
  components: {
    FooterSection,
    Header
  },
  data() {
    return {
      showImage: false,
      showContent: false,
      showAksa: false,
      showTeksan: false,
      showKohler: false,
      showTeksan2: false,

      images: [
        require('@/assets/images/top-bg.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/interior-large-logistics-warehouse-ai-generative.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/bottom-bg.png'),
      ],
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  mounted() {
    this.showImage = true;

    setTimeout(() => {
        this.$refs.description.classList.add('fade-in');
    }, 500);
    setTimeout(() => {
      this.showContent = true;
    }, 500);

    setTimeout(() => {
      this.showAksa = true;
    }, 1100);
    setTimeout(() => {
      this.showTeksan = true;
    }, 1700);
    setTimeout(() => {
      this.showKohler = true;
    }, 2600);
    setTimeout(() => {
      this.showTeksan2 = true;
    }, 2900);
  },

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 1000px;

      @include respond(phone) {
        height: 850px;
      }
      @include respond(lil-phone) {
        height: 900px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.body-section {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 950px;
      /*   @include respond(from-phone-to-tab) {
           height: 650px;
         }*/
      @include respond(lil-phone) {
        height: 1150px !important;
      }
      @include respond(phone) {
        height: 900px;
      }
    }

    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 780px;
      @include respond(phone) {
        height: 1000px !important;
      }
      @include respond(from-phone-to-tab) {
        height: 1000px;
      }

      @include respond(tab-land) {
        height: 900px;
      }
      @media only screen and (min-width: 1201px) and (max-width: 1499px) {
        height: 900px;
      }
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 830px;
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .custom-flex{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    @include respond(phone) {
      margin-top: 0;
    }
  }

  .marquee-content {
    display: flex;
    width: calc(100% * 2); /* Double the container size for duplication */
    animation: marquee 20s linear infinite;
  }

  .logo {
    max-width: 150px;
    height: 125px; /* Set a fixed height */
    flex-shrink: 0;
    margin-right: 50px; /* Space between logos */
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    display: flex;

    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      //   align-items: flex-start;
      align-items: center;
      margin-top: 40px;
      @include respond(phone) {
        flex-direction: column;
        margin-top: 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;

        transform: translateY(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 2s ease-out;
        @include respond(phone) {
          text-align: center;
        }

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .title {
          font-size: 100px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          text-align: center;
          @include respond(phone) {
            font-size: 30px !important;
            width: 100%;
          }
        }

      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;
      }

      &-img {
        background-image: url('~@/assets/images/boschuling-naslovna.png');
        border-radius: 10px;
        width: 500px;
        height: 300px;
        background-size: cover;
        @include respond(phone) {
          display: block !important;
          width: 90vw;
          height: 200px;
          border-radius: 8px;
        }
      }
    }

    .title {
      font-size: 50px;
      font-family: "Montserrat";
      color: $primary-blue;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      @include respond(from-phone-to-tab) {
        font-size: 40px;
      }
    }
  }

  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 40px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .background-image {
    min-height: 40vh;
  }
}
</style>
