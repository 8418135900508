<template>
  <div class="wrapper">
    <div class="body">
      <div class="body__categories" id="proizvodi">
<!--        <div class="single-category" @click="navigateTo('/elektro-motori')">
          <img src="~@/assets/images/generator.png" alt="">
          <div class="name">elektro motori</div>
        </div>-->
        <div class="single-category" @click="navigateTo('/avio-oprema-aviogei')">
          <img src="~@/assets/images/delivery.png" alt="">
          <div class="name">Aviation Equipment</div>
        </div>
        <div class="single-category" @click="navigateTo('/saobracajnica-boschung')">
          <img src="~@/assets/images/highway.png" alt="">
          <div class="name">Road Maintenance Equipment</div>
        </div>
        <div class="single-category" @click="navigateTo('/zeljeznica')">
          <img src="~@/assets/images/highway.png" alt="">
          <div class="name">Railway Maintenance Equipment</div>
        </div>
        <div class="single-category" @click="navigateTo('/elektro-agregati')">
          <img src="~@/assets/images/generator.png" alt="">
          <div class="name">Diesel Generators</div>
        </div>
        <div class="single-category" @click="navigateTo('/ups-ovi')">
          <img src="~@/assets/images/lighting.png" alt="">
          <div class="name">UPS Systems</div>
        </div>
        <div class="single-category" @click="navigateTo('/liftovi-za-osi')">
          <img src="~@/assets/images/disability.png" alt="">

          <div class="name">Mobility Lifts</div>
        </div>


      </div>
      <div class="body__about-us" id="about">
        <div class="count">
          <div class="container count__item" ref="clientsCounter">
            <div v-if="visible.clients" class="counter number">
              {{ currentCount.clients }}
            </div>
            <div class="text">CLIENTS </div>
          </div>
          <div class="container count__item" ref="projectsCounter">
            <div v-if="visible.projects" class="counter number">{{ currentCount.projects }}</div>
            <div class="text">PROJECTS </div>
          </div>
        </div>
        <div class="about-us">
          <div class="title">about us</div>
          <p>
<!--            Sa dugogodišnjim iskustvom, NOVI
            SJEVER MONT je lider u oblasti dizel
            elektro agregata, pumpi za vodu, elektro
            motora, ups-ova, kontrolera i metalnog
            namještaja na teritoriji Crne Gore.
            <br> <br>
            Od svog osnivanja sarađujemo sa
            svijetskim proizvođačima najkvalitetnijih
            proizvoda. Ovaj kvalitet se ne odnosi samo
            na upotrebu najkvalitetnijih komponenti i
            pouzdanosti već i na podršku , prije i
            poslije ugradnje.-->
            With years of experience, NOVI SJEVER MONT is a leader in the field of diesel power generators, water pumps,
            electric motors, UPS systems, controllers, and metal furniture in the territory of Montenegro.
            <br> <br>
            Since our establishment, we have been collaborating with world-class manufacturers of the highest quality
            products. This quality pertains not only to the use of top-quality components and reliability but also to
            the support provided before and after installation.
          </p>
        </div>
        <div class="images">
          <img src="~@/assets/images/o-nama1.jpg" alt="" loading="lazy">
          <img class="img-none" src="~@/assets/images/o-nama2.jpg" alt="" loading="lazy">
        </div>
      </div>
    </div>
  </div>
  <div class="body__advertise">
    <div class="marquee">
      <div class="marquee-content">
        <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
        <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
        <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="body__recommended" id="usluge">
      <div class="body__recommended__title">HIGHLIGHTS</div>
      <div class="body__recommended__content">
        <ul class="grid-custom" ref="iconsList">
          <transition-group
              appear
              name="list"
          >
            <li v-for="(icon, index) in icons" :key="icon.name" :data-index="index" class="list-item-hidden">
              <img :src="icon.name" alt="" loading="lazy">
              <div class="title">
                <h1>{{ icon.text }}</h1>
              </div>
            </li>
          </transition-group>
        </ul>
      </div>
    </div>
    <div class="body__badges mt-4">
      <div class="info-text">
        <!--        Poslujemo već duže od 3 decenije, a zbog naše posvećenosti najvišim poslovnim standardima, dobitnici smo
                sledećih sertifikata:-->
        We have been in business for over three decades, and due to our commitment to the highest business standards, we
        are recipients of the following certificates:
      </div>
      <ul class="d-flex flex-row justify-content-center" ref="certificatesList">
        <transition-group
            appear
            name="list"
        >
          <li v-for="(certificate, index) in certificates" :key="certificate.name" :data-index="index"
              class="list-item-hidden">
            <img style="max-width: 100px" :src="certificate.name" alt="" loading="lazy">
          </li>
        </transition-group>
      </ul>
    </div>
  </div>

</template>

<script>
//import {Swiper, SwiperSlide} from 'swiper/vue';
//import 'swiper/css';
//import 'swiper/css/autoplay';
//import {Autoplay} from 'swiper/modules';
import gsap from 'gsap'
import {ref, onMounted} from 'vue'

export default {
  setup() {
    const icons = ref([
      {name: './img/boschuling-naslovna.png', text: 'BOSCHUNG'},
      {name: './img/kohler.png', text: 'KOHLER'},
      {name: './img/teksan.png', text: 'TEKSAN'},
      {name: './img/aviogei-naslovna.png', text: 'AVIOGEI'},
    ]);
    const certificates = ref([
      {name: './img/cisk1.png',},
      {name: './img/cisk2.png'},
      {name: './img/cisk5.png'},
      {name: './img/cisk3.png'},
      {name: './img/cisk4.png'},
      {name: './img/cisk6.png'},
    ]);
    const certificatesList = ref(null);
    const iconsList = ref(null);

    onMounted(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const isIconsList = entry.target === iconsList.value;
            // this.visibleCategory = true;
            entry.target.querySelectorAll('li').forEach((el, index) => {
              el.classList.remove('list-item-hidden');
              if (isIconsList) {
                gsap.fromTo(el, {
                  opacity: 0,
                  translateX: 300,
                  visibility: 'hidden'
                }, {
                  opacity: 1,
                  translateX: 0,
                  visibility: 'visible',
                  duration: 1.2,
                  delay: index * 0.2
                });
              } else {
                gsap.fromTo(el, {
                  opacity: 0,
                  translateY: 100,
                  visibility: 'hidden'
                }, {
                  opacity: 1,
                  translateY: 0,
                  visibility: 'visible',
                  duration: 1.2,
                  delay: index * 0.2
                });
              }
            });
            //  observer.unobserve(entry.target);
          }
        });
      }, {
        threshold: 0.5
      });

      observer.observe(iconsList.value);
      observer.observe(certificatesList.value);
    });

    return {icons, iconsList, certificates, certificatesList};
  },
  name: 'BodySection',
  props: {
    msg: String
  },
  components: {
    //  Swiper,
    //  SwiperSlide
  },

  data() {
    return {
      // modules: [Autoplay],
      currentCount: {
        clients: 0,
        projects: 0
      },
      targetCount: {
        clients: 415,
        projects: 1140
      },
      visible: {
        clients: false,
        projects: false
      },
      interval: {
        clients: null,
        projects: null
      },
      visibleCategory: false,
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  methods: {
    countUp(counterKey) {
      this.interval[counterKey] = setInterval(() => {
        if (this.currentCount[counterKey] < this.targetCount[counterKey]) {
          this.currentCount[counterKey] += 10; // Increment by 10
          if (this.currentCount[counterKey] > this.targetCount[counterKey]) {
            this.currentCount[counterKey] = this.targetCount[counterKey];
          }
        } else {
          clearInterval(this.interval[counterKey]);
        }
      }, 10);
    },
    setupObserver(element, counterKey) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.visible[counterKey] = true;
            this.countUp(counterKey);
            observer.unobserve(entry.target);
          }
        });
      }, {threshold: 0.5});

      observer.observe(element);
    },
    navigateTo(route) {
      console.log(route)
      this.$router.push(route);
    }
  },
  mounted() {
    this.setupObserver(this.$refs.clientsCounter, 'clients');
    this.setupObserver(this.$refs.projectsCounter, 'projects');
  },


}
</script>

<style lang="scss">
@import "~@/assets/main.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .body {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__categories {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 150px 0;
      background: $light-blue;
      border-radius: 10px;
      justify-content: space-evenly;
      @include respond(from-phone-to-tab) {
        margin: 50px 0;
      }
      @include respond(phone) {
        margin: 10px 0;
        border-radius: 8px;
      }

      .single-category {
        background: $light-blue;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
        padding: 40px 20px;
        transition: transform 0.25s ease;
        cursor: pointer;

        @include respond(from-phone-to-tab) {
          gap: 15px;
          padding: 20px 20px;
        }
        @include respond(phone) {
          padding: 20px 10px;
        }

        .name {
          text-transform: uppercase;
          color: white;
          font-size: 17px;
          font-weight: 600;
          line-height: 1.2;
          letter-spacing: 0.6px;
          font-family: "Montserrat";
          width: 139px;
          @include respond(from-phone-to-tab) {
            font-size: 12px;
          }
        }

        img {
          width: 60px;
          @include respond(from-phone-to-tab) {
            width: 40px;
          }
        }

        &:hover {
          background: $primary-blue;
          transform: scale(1.2, 1.25);
        }
      }
    }

    &__about-us {
      font-family: "Montserrat";
      display: flex;
      align-items: center;
      width: 100%;
      @include respond(from-phone-to-tab) {
        flex-direction: column-reverse;
        img {
          border-radius: 10px;
        }
      }
      @include respond(phone) {
        flex-direction: column-reverse;
        img {
          border-radius: 4px;
        }
        .img-none {
          display: none;
        }
      }

      .count {
        background: $light-blue;
        padding: 45px 20px;
        border-radius: 10px 0 0 10px;
        font-weight: bold;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 85px;
        @include respond(from-phone-to-tab) {
          flex-direction: row;
          border-radius: 0 0 8px 8px;
        }
        @include respond(phone) {
          flex-direction: row;
          border-radius: 4px 4px 0 0;
        }


        .number {
          font-size: 46px;
          color: white;
          @include respond(phone) {
            font-size: 35px;
          }
        }

        .text {
          font-size: 21.79px;
          text-transform: uppercase;
          color: $primary-blue;
          @include respond(phone) {
            font-size: 20px;
          }
        }
      }

      .about-us {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: white;
        background: $primary-blue;
        line-height: 1.2;
        text-align: left;
        padding: 50px;
        border-radius: 10px;
        min-height: 550px;

        @include respond(phone) {
          padding: 25px;
        }

        .title {
          font-size: 44px;
          font-family: "Montserrat";
          font-weight: bold;
          text-transform: uppercase;
          padding-bottom: 2rem;
          @include respond(phone) {
            font-size: 40px
          }
        }

        p {
          font-size: 23px;
          font-family: "Karla";
          text-align: justify;
          @include respond(phone) {
            font-size: 20px
          }
        }

        @include respond(from-phone-to-tab) {
          min-height: 430px;
          .title {
            font-size: 40px;
          }
          p {
            font-size: 20px;
          }
        }

      }

      .images {
        display: flex;
        flex-direction: column;
        @include respond(from-phone-to-tab) {
          flex-direction: row;
        }

        img {
          width: 350px;
          height: 250px;
          border-radius: 0 10px 10px 0;
          object-fit: cover;
        }
      }
    }

    &__recommended {
      margin-top: 45px;

      &__title {
        font-size: 60px;
        font-family: "Montserrat";
        color: $light-blue;
        font-weight: bold;
        line-height: 1.2;
        @include respond(from-phone-to-tab) {
          font-size: 50px;
        }
        @include respond(phone) {
          font-size: 50px;
        }
      }

      &__content {
        @include respond(from-phone-to-tab) {
          margin-left: -30px;
        }
        @include respond(phone) {
          margin-left: -30px;
        }

        img {
          width: 350px;
          position: relative;
          height: 200px;
          object-fit: cover;
          border-radius: 8px;
          box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
          @include respond(from-phone-to-tab) {
            max-width: 250px;
          }
          @include respond(phone) {
            max-width: 200px;
            height: 150px;
          }
        }

        h1 {
          color: white;
          font-size: 40px;
          font-weight: 600;
          // width: 220px;
          line-height: 1;
          text-align: end;
          padding-right: 10px;
          @include respond(from-phone-to-tab) {
            font-size: 30px;
          }
          @include respond(phone) {
            font-size: 20px;
          }
        }

        .title {
          position: absolute;
          bottom: 0;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: end;
        }

        li {
          list-style-type: none;
          border-radius: 10px;
          box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          line-height: 1.5em;
        }

        .grid-custom {
          display: grid !important;
          grid-template-columns: 1fr 1fr !important;
          grid-gap: 20px !important;
          margin: 40px auto !important;
          @include respond(phone) {
            grid-gap: 10px !important;
          }
        }
      }

    }

    &__badges {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      max-width: 700px;

      @include respond(phone) {
        max-width: 400px;
        ul {
          padding-left: 0px;
        }
        li {
          padding: 0px 7px !important;
        }
        img {
          width: 60px;
        }
      }
      @include respond(lil-phone) {

        img {
          width: 45px;
        }
      }
      @include respond(from-phone-to-tab) {
        li {
          padding: 0px 10px !important;
        }
        img {
          width: 90px;
        }
      }

      .info-text {
        font-size: 18px;
        font-family: "Karla";
        color: rgb(255, 255, 255);
        line-height: 1.2;
        text-align: center;

        @include respond(from-phone-to-tab) {
          padding: 0 60px;
          font-size: 16px;
        }
        @include respond(phone) {
          font-size: 16px;
        }
      }

      li {
        list-style-type: none;
        padding: 0 20px;
        @include respond(lil-phone) {
          padding: 1px;
        }
      }

    }

    .list-item-hidden {
      opacity: 0;
      transform: translateX(300px); /* For icons */
      visibility: hidden; /* Ensures content is not accidentally interactable */
    }
  }
}

.body {
  &__advertise {
    width: 100vw;
    margin: 100px 0;
    @include respond(from-phone-to-tab) {
      margin: 50px 0;
    }
    @include respond(phone) {
      margin: 10px 0;
    }

    .marquee {
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    .marquee-content {
      display: flex;
      width: calc(100% * 2); /* Double the container size for duplication */
      animation: marquee 20s linear infinite;
    }

    .logo {
      max-width: 150px;
      height: 125px;
      flex-shrink: 0;
      margin-right: 50px;

      @include respond(from-phone-to-tab) {
        height: 100px;
      }
    }

    @keyframes marquee {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-50%); /* Move by half of the total width */
      }
    }
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(52 105 171);
    border-bottom: 2.5px solid rgb(52 105 171);
    padding-top: 60px;

    &-item {
      font-weight: 650;
      font-size: 22px;
      text-transform: uppercase;
      font-family: 'Montserrat';
      padding: 10px 15px;
      cursor: pointer;
      position: relative;

      &:hover {
        font-size: 24px;
        padding: 8px 9.5px;
        color: rgba(52, 105, 171, 0.84);

        .dropdown-content {
          max-height: 500px;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .dropdown-content {
      font-family: 'Montserrat';
      position: absolute;
      background-color: #25508BD7;
      border-radius: 10px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      width: 100%;
      left: 0;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transform: translateY(-10px);
      transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;


      .dropdown-item {
        font-size: 18px;
        padding: 12px 10px;
        text-decoration: none;
        text-align: left;
        display: block;
        color: white;

        &:hover {
          border-bottom: 1.5px solid rgba(255, 255, 255, 0.6);
          font-weight: 600;
        }

        .submenu {
          display: none;
          position: absolute;
          left: 100%;
          top: 0;
          background-color: #25508BD7;
          border-radius: 10px;
          min-width: 160px;
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
          visibility: hidden;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          transform: translateY(-10px);
          transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease; // Smooth transition
        }

        &.has-submenu:hover .submenu {
          display: block;
          max-height: 500px;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .arrow {
      float: right;
    }
  }


}
</style>
