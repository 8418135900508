<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="header">
              <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
                <div :class="{ 'show': showContent }" class="header__section-info">
                  <div class="title">Aviation Equipment <br> <span>BOSCHUNG </span></div>
                </div>
                <transition name="fade">
                  <div v-if="showImage" class="header__section-img"></div>
                </transition>
              </div>
            </div>
            <div class="description" ref="description">
              <b>Boschung</b> offers an unprecedented suite of trans-seasonal equipment that, year after year, continues
              to
              redefine what is possible. Their engineering feats clean the roads and surfaces for the world’s largest
              airports, busiest highways and most visited cities and municipalities across the globe.**
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
        </div>

        <div class="content">

          <div class="box box__left" :class="{ 'show': showLeft}">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  URBAN SWEEPER S2.0
                </div>
                <div class="container-content__description container-content__description-left">
                  <div class="container-content__description container-content__description-left">
                    Meet the Urban-Sweeper S2.0, the first fully electric street sweeper releasing 0 (zero) emission and
                    engineered by Boschung. A 2m3 sweeper combining ease and suction power with the most progressive
                    technologies of today. <br>
                    The Urban-Sweeper S2.0 was designed with efficiency in mind, providing an exceptionally light weight
                    and narrow width for maximum reach and suction performance. The Urban-Sweeper S2.0 can fit into the
                    narrowest streets while providing the highest suction performance. With a chassis made out of a high
                    resistant metal, the sweeper can hold a total gross weight of 3,500 kg or 4,000kg.
                  </div>
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/urban-sweeper-s2-0/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left box-img-special" src="~@/assets/images/avio/UrbanSweeperS2.0.png"
                   alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title align">
                  JETBROOM 10000
                </div>
                <div class="container-content__description container-content__description-right">
                  A multifunctional high-performance clearing system of progress, the Jetbroom 10000, and its iconic
                  one-piece heavy-duty chassis, was built for extreme and engineered for efficiency. The over 6 m long
                  brush is equipped with the new and patented Quattro-Jet, a system that enables a larger clearing width
                  and an accelerated work speed. A system that makes the Jetbroom the most powerful work tool of today.
                  The new Jetbroom Series is more than an evolution, it represents a fundamental reengineering of the
                  Jetbroom concept. The new cutting-edge technology is made of two blowing unit systems: one behind the
                  broom and one in front of the broom, the new and patented Quattro-Jet system.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/jetbroom-10000/" class="btn">
                    View more details
                  </a></div>
              </div>
              <img class="box-img box-img&#45;&#45;right" src="~@/assets/images/avio/Jetbroom10000.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft2 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  JETBROOM 4000
                </div>
                <div class="container-content__description container-content__description-left">
                  Built to give unrestrained high-speed performance, the Jetbroom 4000 and its kinetic design is compact
                  and highly maneuverable. With a 13.7 m turning circle on the outer-wheel, the Jetbroom 4000 exceeds
                  expectations when it comes to flexibility and mobility around narrow apron areas, taxiways and
                  runways. Equipped with a 5.4 m wide snow plow, a 4.2 m long broom and a blowing unit placed behind the
                  rear axle, the Jetbroom 4000 is a machine engineered with innovative and progressive technologies. The
                  powerful Jetbroom 4000 merges exceptional performance and fast snow clearing operation. The very small
                  turning radius of the vehicle is achieved by a special rear axle configuration giving the Jetbroom
                  4000 the ability to work on runways, taxiways and apron areas with ease and efficiency.
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/jetbroom-4000/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left box-img-special" src="~@/assets/images/avio/jetbroom4000.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight2 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  PONY P3.0
                </div>
                <div class="container-content__description container-content__description-right">
                  Agile, versatile and flexible the Pony P3.0 is the result of over 50 years of constant engineering and
                  development work, transforming the 1970 Pony from a revolutionizing work tool to a most efficient,
                  sustainable and modernized better version of itself. Fully powered by electricity, the Pony P3.0 was
                  engineered to offer maximum efficiency all year-round with over 9 different equipment types while
                  release 0 (zero) emission. The Quick Switch System allows a complete transformation of the
                  multipurpose carrier in just 10 minutes. Equipped with an All Wheel Drive powertrain driven by four
                  electric engines the Pony P3.0 delivers unparalleled performance in all weather conditions. The
                  Boschung engineered 54.4 kWh lithium-ion battery at the heart of the vehicle gives the power to all
                  engines and system granting an entire shift of work in just 100 minutes charging time.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/pony-p3-0/" class="btn"> View more details</a>
                </div>
              </div>
              <img class="box-img box-img--right box-img-special" src="~@/assets/images/avio/PonyP3.0.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft3 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  IMS SERIES
                </div>
                <div class="container-content__description container-content__description-left">
                  Intelligence and automation for flawless precision and sustainability, the IMS Series uses innovative
                  components and state-of-the-art technologies for the maximum spreading accuracy. One clever science,
                  the OptiWet® distribution system hides an array of engineering masterpieces for the perfect mixture
                  and spreading pattern at any speed. Only when the perfect amount of salt is spread in the perfect
                  pattern can de-icing become sustainable and eco-friendly. Because differences in road conditions,
                  changes in velocity, spreading width and spreading density must all be taken into consideration to
                  achieve the perfect de-icing, IMS gives you an automated system to achieve the perfect spreading
                  regardless of the conditions. The driver can focus onto traffic while the intelligent spreading
                  technology takes care of achieving the perfect de-icing job.
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/ims/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/BoschungIMSSERIES.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight3 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  FH Serie Vehicle hydraulic system
                </div>
                <div class="container-content__description container-content__description-right">
                  Standardized hydraulic systems according to customer requirements and for every vehicle manufacturer
                  with maximum efficiency and performance. The FH vehicle hydraulic systems are application-specific
                  solutions developed for municipal use. The experience gained since the company was founded is used in
                  today's vehicle hydraulic systems in a wide variety of configurations. Only if every application can
                  be fulfilled in municipal use is the system suitable for demanding use. Ultimately, we offer system
                  solutions for every vehicle manufacturer in order to be able to tailor solutions to customer
                  requirements.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/vehicule-hydraulic-system/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/FSerieHehicle.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft4 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  bMoves
                </div>
                <div class="container-content__description container-content__description-left">
                  bMoves is a geo-tracked field activities management solution designed for professional road and runway
                  operations. Available on the field and in the office, bMoves enables you to document, visualize and
                  export everything you do. Thanks to our field-proven mobile application, operators can document their
                  tasks directly on the job. Built with an intuitive interface, bMoves app enables operators to record
                  their activities with a simple click. Even better, our powerful algorithm automatically store where
                  and when a task was carried out.
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/bmoves/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/bMoves-FieldService.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight4}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  Urban-Sweeper S2.0 Autonomous
                </div>
                <div class="container-content__description container-content__description-right">
                  Proven performance, powered by Electric, the Urban-Sweeper S2.0 is now autonomous, driven by WIBOT a
                  high-tech autonomous company. <br>
                  Equipped with a combination of lidars, cameras, mm-waves radars and gnss antennas, the Urban-Sweeper
                  S2.0 Autonomous has a 360° coverage of its surrounding environment. The driverless street sweeper can
                  not only be used in closed areas, it can safely sweep the public streets with a level 5 certification.
                  The low noise pollution of the electric sweepers enables a 24h use. The accurate and efficient
                  recognition algorithm allows the sweeper to track all objects in sight simultaneously, leaving no
                  chance to dirt. Switch to manual mode and drive the Urban-Sweeper S2.0, at your own risks!

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/urban-sweeper-s2-0-autonomous/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/UrbanSweeperautonomus.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft5 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  bVision
                </div>
                <div class="container-content__description container-content__description-left">
                  Management designed for the perfectionists. Accessible from your computer or on the go with your
                  smartphone, bVision is a management platform offering the tools for smart operations and complex
                  decision-making situations. Specifically designed for both roads and runways, bVision drastically
                  helps improve efficiency by connecting all your yearly maintenance activities into the same platform.
                  Integration has come full circle with the bVision platform. Today you can forecast, track and analyze
                  your connected weather stations, automated spray system FAST, maintenance vehicles and worktools on
                  the same platform.
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/bvision/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/bVision.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight5}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  Snowbooster Series
                </div>
                <div class="container-content__description container-content__description-right">
                  Iconic since day one, the Snowbooster series offers a unique performance that blends power with modern
                  technology into a daring snow blower design. With a clearing capacity of up to 5,000 tons per hour,
                  the Snowbooster series blends maximum efficiency with versatility and workability. Progress is made
                  when you see things differently and with state-of-the-art innovations merging interaction between
                  driver and machine, the Snowbooster series cuts through snow like no others. With a clearing capacity
                  of up to 5,000 tons per hour, the Snowbooster series offers exceptional powers tailored to your
                  intensive winter maintenance needs. With up to 2.7 m clearing width, the Snowbooster series was built
                  to cut through snow like no others and hurls snow up to 60 m away.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/snowbooster/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/SnowboosterSeries.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft}">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  ATLAS
                </div>
                <div class="container-content__description container-content__description-left">
                  <div class="container-content__description container-content__description-left">
                    The International Civil Aviation Organisation (ICAO) has established the Global Reporting Format
                    (GRF) in an effort to globally harmonized runway condition assessment and with a clear objective to
                    reduce runway excursions. Based on the combination of several field-proven sensing technologies,
                    Boschung ATLAS (Automated Take-off and Landing Assessment System) provides all contaminant types and
                    depth required by the GRF. For year-round, 24/7 operations of your runway. ATLAS monitors the
                    presence of contaminants on the runway surface, which may have detrimental effects on braking
                    actions, with an upmost danger of runway excursions. The system provides in real time surface
                    condition assessment according to ICAO’s requirement for Global Reporting Format (GRF): Runway
                    condition code (RWYCC), a number between 0 and 6 that reflects the runway braking capability as a
                    function of the surface condition
                    <br> - A normalized description of the type of contamination on the runway
                    <br> - An assessment of the depth of the contamination
                    <br> - An assessment of the contamination coverage

                  </div>
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/atlas/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left box-img-special" src="~@/assets/images/avio/BoschungATLAS.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title align">
                  FlexiWet®
                </div>
                <div class="container-content__description container-content__description-right">
                  Years of experience in de-icing have let us to one conclusion: prevention is key to safety and
                  prevention can only be achieved with maximum efficiency if done using the full liquid technology. With
                  FlexiWet®, you can now turn your salt spreader in a full liquid spreader in just about 10 minutes. Use
                  the same vehicle through all phases of winter. Years of experience with the de-icing of traffic
                  surfaces have led us to one conclusion: prevention of the formation of ice is only efficient with a
                  fully liquid solution. A 100% solution of brine is spread on the surface before the freezing occures,
                  the solution will stick to the ground until temperature drops, evaporating the water and leaving on
                  the surface the small particle of salt to prevent the formation of ice. Today with the patented
                  Boschung FlexiWet®, install the flexible bag directly into your salt tank and use the same spreader
                  adapted to all winter requirments.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/flexiwet/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img&#45;&#45;right" src="~@/assets/images/avio/FlexiWet.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft2 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Jetbroom 10000 T
                </div>
                <div class="container-content__description container-content__description-left">
                  High-performance clearing system of progress, the Jetbroom 10000 T and its newly engineered chassis
                  concept was built to perform with the most efficiency on the busiest airports of the world. The over 6
                  m long brush is equipped with the new and patented Quattro-Jet system, enabling a larger clearing
                  width and an accelerated work speed during the most extreme winter conditions. The Jetbroom 10000 T is
                  more than an evolution, it represents a fundamental reengineering of the Jetbroom concept. The new
                  cutting-edge technology is made of two blowing unit systems: one behind the broom and one in front of
                  the broom, the new patented Quattro-Jet system. While the accelerated rotation of the broom lifts up
                  snow, slush or water, the 4 jet nozzles fixed in the front of the broom blast everything lifted to the
                  side. The second blower unit, placed behind the broom, blows off any leftover leaving behind a
                  perfectly clean, nearly dry surface.

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/jetbroom-10000-t/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left box-img-special" src="~@/assets/images/avio/jetbroom1000T.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight2 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  SDA Series
                </div>
                <div class="container-content__description container-content__description-right">
                  Controlability and precision for unique performance, the SDA Series offers the rigor and efficiency
                  required to work on the busiest airports. Engineered with the latest, state-of-the-art Boschung
                  technologies, the SDA Series covers surfaces from 3 m and up to 30 m wide with liquid, solid and mixed
                  de-icing agent. Built to reach the furthest, the SDA series was designed with rigor in mind ensuring
                  perfect spraying at maximum speed. Modular and adjustable, the SDA series can cover a 3 m to 24 m span
                  with liquid/solid de-icing agent and up to 30 m with liquid de-icing.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/sda/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right box-img-special" src="~@/assets/images/avio/BoschungSDAseries.png"
                   alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft3 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  FAST
                </div>
                <div class="container-content__description container-content__description-left">
                  Because seconds are crucial for safety, eliminate risks of ice formation on your high exposure and
                  high traffic surfaces with the proven FAST, Fixed Automated Spray Technology, system. An entirely
                  automatic liquid de-icing system triggered directly by the Boschung active sensor technology. In just
                  a matter of seconds, the de-icing agent is spread out, covering the surface just seconds before ice
                  formation. Safety and fluidity are assured in the most sustainable way. Engineered for high risk, high
                  traffic surfaces and accident traps, where de-icing with a vehicle would be too demanding, the
                  Boschung FAST system offers the comfort of automation while guaranteeing a job done with precision for
                  maximum safety and efficiency.

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/fast/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/FastSystem.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight3 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  CombiWet®
                </div>
                <div class="container-content__description container-content__description-right">
                  Ingenuity and adaptability for precice spreading throughout all phases of winter, CombiWet® gives you
                  the tools for preventive full liquid and de-icing spreading in multiple possibilities. Efficiency and
                  sustainability are handled with the the OptiWet® distribution system, hiding an array of engineering
                  masterpieces for the perfect mixture and spreading pattern at any speed. One vehicle, a multitude of
                  spreading actions. State-of-the-art engineering for all-in-one de-icing operations throughout all
                  phases of the winter. Prevent and remove the danger with one vehicle equipped with an array of
                  technologies set up for maximum sustainability and efficiency.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/combiwet/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/CombiWet.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft4 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Ice Early Warning System Airport
                </div>
                <div class="container-content__description container-content__description-left">
                  The secret of safety and efficiency lies behind Boschung with over 40 years of experience in Ice Early
                  Warning Systems. Today we give you the chance to be ahead of severe winter conditions with
                  technologies able to predict the future of your runway condition at all time and regardless of the
                  type of chemicals you use. With a combination of the measurements taken by meteorological sensors and
                  active pavement sensors, the Boschung Ice Early Warning System gives you the keys for smart decision
                  making and the possibility to reduce the number of de-icing operations with maximum security.

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/ice-early-warning-system-airport/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/iceEarly.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight4}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  FullWet®
                </div>
                <div class="container-content__description container-content__description-right">
                  Safety and sustainability for safe traffic ways with the fully liquid FullWet® technology. Available
                  in different size, the Fullwet® technology is adjusted to the size of your vehicle. Because preventing
                  the danger from happening is key for maximum safety and efficiency. Built on a solid frame, FullWet®
                  can be adjusted to your size and attachment needs. Made out of shock-resistant, maintenance-free, and
                  no rust PE materials, the cascaded liquid tanks offer the most flexibility while allowing you to use
                  the payload of your vehicle to its maximum. The modular system comes in a variation of three tank
                  capacities. Three flow joiners were engineered to connect the tanks, guaranteeing perfect fluid
                  transfer and leveling at all times.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/fullwet/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/CombiWet.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft5 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Multi-segmented snowplow
                </div>
                <div class="container-content__description container-content__description-left">
                  With a full range of multi-segemented snowplows, Boschung offers the best snow clearing technology in
                  all sizes. Reinforced frame with higher segment for mountain clearing, double springs and angle
                  restriction for the shock absorbing rail..Available in various versions, with joystick, rotary knobs
                  and more, the Vpad is a simplified interface controlling nearly every aspects of your spreading
                  experience.

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/mf-snowplow/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/Snowplows.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight5}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  r-condition
                </div>
                <div class="container-content__description container-content__description-right">
                  The r-condition was created to determine the surface condition and measure the surface temperature,
                  without any contact on the road, both at the same time and from one single device. It applies the most
                  advanced technologies such as optical backscattering. The Boschung engineered and patented device is
                  the only non-invasive sensor able to detect danger of ice before the actual formation of ice. With low
                  power consumption, it can be easily used as a complete standalone measuring station, in combination
                  with other sensors on a road weather station or integrated onto a third-party road safety application.
                  Thanks to a technology patented by Boschung, the r-condition is the first non-invasive road condition
                  sensor that can provide early warnings before the formation of frost. To date, such devices could only
                  provide real time data detecting ice only after the danger was already present on the roadway!


                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/r-condition/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/r-condition.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft3 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  r-weather
                </div>
                <div class="container-content__description container-content__description-left">
                  Combining all the measuring functionalities of the most important weather conditions, the r-weather is
                  a unique and self-cleaning instrument. Visibility, precipitation type, intensity and quantity, air
                  temperature, relative humidity, dew point and frost point, barometric pressure, wind speed and wind
                  direction are all measured from one tool, easy to install and operate. With the ability to measure the
                  level of reduced visibility and air temperature, the r-weather detects the very dangerous freezing
                  fog.

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/r-weather/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/r-weather.png" alt="">
            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight3 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  IT-ARCTIS
                </div>
                <div class="container-content__description container-content__description-right">
                  With the active technology, the pavement sensor installed directly into the surface will automatically
                  cool down its surface until freezing occurs, measuring the temperature of ice formation. By comparing
                  the surface temperature with the freezing point temperature of the moisture, the degree of protection
                  is known regardless of the type of chemical already applied.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/it-arctis/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/it.png" alt="">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft4 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Vpad
                </div>
                <div class="container-content__description container-content__description-left">
                  Work smarter and safer. The engaging operating system Vpad and its 7″ touchscreen color display is the
                  link between your mind and your machine. Voice guidance system and automated GPS controlled spreading
                  ensure a work done with the most efficiency and safety. The ergonomics of the design and the 7″
                  touchscreen color display make the Vpad the one connection between a man and its machine Intuitive and
                  sophisticated, the Vpad offers a simplified interface to control nearly every aspect of your working
                  experience. The voice guidance system was engineered to increase safety and help the driver monitor
                  its working adventures while keeping his focus on the road and traffic.

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/vpad/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/Vpad.png" alt="">

            </div>
          </div>

          <div class="box box__right" :class="{ 'show': showRight4}">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  FH/PL
                </div>
                <div class="container-content__description container-content__description-right">
                  The vehicle is equipped with one or two mounting plates to accommodate front or side implements. The
                  size of the mounting plate depends on the carrier vehicle and enables, for example, the mounting of a
                  snow plow or sweeping broom using the quick-change method. The current European DIN EN standards as
                  well as the Swiss standard VSS are applied, each in different sizes.

                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.boschung.com/product/fh-pl-vehicule-hydraulic-system/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/avio/FH-PL.png" alt="">
            </div>
          </div>
          <div class="box box__left" :class="{ 'show': showLeft5 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  FH/BL
                </div>
                <div class="container-content__description container-content__description-left">
                  Road safety is something that deserve special attention especially during night time winter road
                  maintenance opperations. Visibility is as important to the driver as it is to other users on the road.

                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.boschung.com/product/fh-bl-vehicule-hydraulic-system/" class="btn">
                    View more details
                  </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/avio/FH-BL.png" alt="">

            </div>
          </div>

          <div class="marquee">
            <div class="marquee-content">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'AvioBoschung',
  components: {
    FooterSection,
    Header
  },
  data() {
    return {
      showImage: false,
      showContent: false,
      showLeft: false,
      showRight: false,
      showLeft2: false,
      showRight2: false,
      showLeft3: false,
      showRight3: false,
      showLeft4: false,
      showRight4: false,
      showLeft5: false,
      showRight5: false,

      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  mounted() {
    this.showImage = true;
    setTimeout(() => {
      this.showContent = true;
    }, 500);
    setTimeout(() => {
      this.showLeft = true;
    }, 1000);
    setTimeout(() => {
      this.showRight = true;
    }, 1600);
    setTimeout(() => {
      this.showLeft2 = true;
    }, 2500);
    setTimeout(() => {
      this.showRight2 = true;
    }, 2800);
    setTimeout(() => {
      this.showLeft3 = true;
    }, 3200);
    setTimeout(() => {
      this.showRight3 = true;
    }, 3300);
    setTimeout(() => {
      this.showLeft4 = true;
    }, 3400);
    setTimeout(() => {
      this.showRight4 = true;
    }, 3400);
    setTimeout(() => {
      this.showLeft5 = true;
    }, 3500);
    setTimeout(() => {
      this.showRight5 = true;
    }, 3500);
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.description.classList.add('fade-in');
      });
    }, 500);
  },
  methods: {}

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 1000px;
      @include respond(phone) {
        height: 900px;
      }
      @include respond(phone) {
        height: 1000px;
      }
      @include respond(from-phone-to-tab) {
        height: 1050px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.body-section {
  position: relative;
  overflow: hidden;
  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 640px;
      @include respond(phone) {
        height: 1000px;
      }
      @include respond(lil-phone) {
        height: 1940px;
      }
      @include respond(from-phone-to-tab) {
        height: 870px;
      }
      @include respond(desktop) {
        height: 700px;
      }
    }

    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
      //  position: relative;
      width: 100%;
      height: 1500px;
      @include respond(phone) {
        height: 1325px;
      }
      @include respond(lil-phone) {
        height: 850px;
      }
      @include respond(from-phone-to-tab) {
        height: 1750px;
      }
      @include respond(desktop) {
        height: 1600px;
      }
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 830px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
     // overflow-x: auto;
    //  height: 4200px;
    }
  }

  .height-400 {
    height: 400px !important;
    @include respond(phone) {
      height: 250px !important;
    }
  }

  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
    @include respond(phone) {
      margin-top: 0;
    }
  }

  .marquee-content {
    display: flex;
    width: calc(100% * 2); /* Double the container size for duplication */
    animation: marquee 20s linear infinite;
  }

  .logo {
    max-width: 150px;
    height: 125px; /* Set a fixed height */
    flex-shrink: 0;
    margin-right: 50px; /* Space between logos */
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      //   align-items: flex-start;
      align-items: center;
      margin-top: 40px;
      @include respond(phone) {
        flex-direction: column;
        margin-top: 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;

        transform: translateY(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 2s ease-out;
        @include respond(phone) {
          text-align: center;
        }

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .title {
          font-size: 100px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          text-align: center;
          @include respond(phone) {
            font-size: 30px !important;
            width: 100%;
          }

          span {
            color: $light-blue;
          }
        }

      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;
      }

      &-img {
        background-image: url('~@/assets/images/boschuling-naslovna.png');
        border-radius: 10px;
        width: 500px;
        height: 400px;
        background-size: cover;
        @include respond(phone) {
          display: block !important;
          width: 90vw;
          height: 300px;
          border-radius: 8px;
        }
      }
    }

    .title {
      font-size: 50px;
      font-family: "Montserrat";
      color: $primary-blue;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      @include respond(from-phone-to-tab) {
        font-size: 40px;
      }
    }
  }

  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 30px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .pdfs {
    max-width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;

    .pdf {
      .btn {
        background: linear-gradient(180deg, rgba(93, 132, 183, 0.85) 0%, rgba(36, 85, 151, 0.97) 85%);
        border: none;
        color: white;
        padding: 10px 30px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        transition: transform 0.7s ease-out, color 1s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

</style>
