<template>
  <div class="wrapper" id="contact">
    <img class="logo" src="~@/assets/images/logo.png" alt="" loading="lazy">
    <div class="footer-container">
      <div class="footer-container__left">
        <div class="item">
          <img src="~@/assets/images/location.png" alt="" loading="lazy">
          <a href="https://maps.app.goo.gl/VVYb5L3Hm26Zqdkf7">
            <div class="text">
              Dr Vukašina Markovića 218, broj 1, <br class="phone-none">
              Podgorica, Crna Gora
            </div>
          </a>

        </div>
        <div class="item">
          <img src="~@/assets/images/phone-call.png" alt="" loading="lazy">
          <div class="text">
            <a class="info-item number" href="tel:+38268619444">(+382) 68 619 444</a>
            / <a class="info-item number" href="tel:+38267679314"> 67 679 314</a>
          </div>
        </div>
        <div class="item">
          <img src="~@/assets/images/email.png" alt="" loading="lazy">
          <a href="mailto:office@novisjevermont.me">
            <div class="text">office@novisjevermont.me</div>
          </a>

        </div>
      </div>
      <div class="footer-container__right">
        <form
            action="https://formspree.io/f/xayrvprn"
        method="POST"
        @submit.prevent="handleSubmit"
        >
        <div class="flexx">
          <input
              name="name"
              v-model="name"
              placeholder="NAME AND SURNAME"
              required
          >
          <input
              name="contact"
              v-model="contact"
              placeholder="PHONE/MAIL"
              required
          >
          <input
              name="message"
              v-model="message"
              placeholder="MESSAGE"
              required
          >
          <button type="submit" class="btn">Send</button>
        </div>
        </form>
<!--        <div class="flexx">
          <input
              v-model="name"
              placeholder="NAME AND SURNAME"
              required
          >
          <input
              v-model="contact"
              placeholder="PHONE/MAIL"
              required
          >
          <input
              v-model="message"
              placeholder="MESSAGE"
              required
          >
          <div class="btn">send</div>
        </div>-->
      </div>
    </div>

    <div class="pwd-by">proudly powered by <a href="https://exacta.agency/"><b> exacta.agency</b></a></div>
  </div>
</template>

<script>

export default {
  name: 'FooterSection',
  props: {
    msg: String
  },
  data: () => ({
    valid: false,
    name: '',
    contact: '',
    nameRules: [
      value => {
        if (value) return true

        return 'Name is required.'
      },
      value => {
        if (value?.length <= 10) return true

        return 'Name must be less than 10 characters.'
      },
    ],
    message: '',
    emailRules: [
      value => {
        if (value) return true

        return 'E-mail is requred.'
      },
      value => {
        if (/.+@.+\..+/.test(value)) return true

        return 'E-mail must be valid.'
      },
    ],
  }),
  methods: {
    async handleSubmit() {
      if (!this.name || !this.contact || !this.message) {
        alert('All fields are required!');
        return;
      }

      try {
        const response = await fetch('https://formspree.io/f/xayrvprn', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.name,
            contact: this.contact,
            message: this.message
          })
        });

        if (!response.ok) {
          throw new Error('Failed to process the data');
        }

        const result = await response.json();
        console.log('Preprocessing result:', result);

        this.$el.querySelector('form').submit();
      } catch (error) {
        console.error('Error during form preprocessing:', error);
        alert('There was a problem processing your form.');
      }
    }

  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/main.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    top: 150px !important;
    width: 200px;


    @include respond(phone) {
      top: 70px !important;
      width: 120px;
    }
    @include respond(from-phone-to-tab) {
      top: 90px !important;
      width: 140px;
    }

  }

  .footer-container {
    background: $primary-blue;
    border-radius: 10px;
    width: 85vw;
    height: 600px;
    display: flex;
    justify-content: space-between;
    padding: 150px 150px 80px;
    align-items: center;

    @include respond(from-phone-to-tab) {
      border-radius: 7px;
      width: 95vw;
      padding: 0px 20px 0;
      flex-direction: column;
      gap: 0;
      justify-content: center;
      align-items: flex-start;
    }
    @include respond(tab-land) {
      padding: 50px 50px 0px;
      height: 350px;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      padding: 150px 50px 80px;
    }
    @include respond(phone) {
      padding: 50px 20px 0px;
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 30px;

      .item {
        display: flex;
        gap: 20px;
        align-items: center;
        cursor: pointer;
        text-align: left;
        @include respond(phone) {
          gap: 10px;
          .phone-none {
            display: none;
          }
        }
        @include respond(from-phone-to-tab) {
/*          gap: 10px;
          img {
            width: 20px !important;
          }
          .text {
            font-size: 20px !important;
          }*/
        }
        img {
          width: 35px;
          top: 0;
          @include respond(phone) {
            width: 25px;
          }
        }

        .text {
          font-size: 22px;
          font-family: "Montserrat";
          color: rgb(255, 255, 255);
          line-height: 1.2;
          transition: color 0.9s ease;
          @include respond(phone) {
            font-size: 16px;
          }
          &:hover {
            color: $light-blue;
          }
        }
        a {
          text-decoration: none;
          color: white;
          transition: color 0.9s ease;
          &:hover {
            color: $light-blue;
          }
        }
      }
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      @include respond(from-phone-to-tab) {
        display: none;
      }
      @include respond(phone) {
        display: none;
      }
      .flexx {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      input {
        background: transparent;
        box-shadow: none;
        border: none;
        border-bottom: 3px solid white;
        color: white;
        width: 400px;
        margin-bottom: 15px;
        padding: 8px;

        &::placeholder {
          font-size: 18px;
          color: rgba(255, 255, 255, 0.40);
          font-family: "Montserrat";
          line-height: 1.2;
        }
        &:focus-visible {
          outline: none;
        }
      }

      .btn {
        font-size: 20px;
        font-family: "Montserrat";
        color: $primary-blue;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.2;
        background: white;
        border-radius: 8px;
        padding: 4px 18px;
        width: 150px;
        margin-top: 25px;
        transition: background 0.6s ease-in-out;
        &:hover {
          background: $light-blue;
          color: white;
        }
      }
    }
  }

  .pwd-by {
    color: white;
    font-family: "Montserrat";
    cursor: pointer;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    b {
      padding-left: 3px;
      transition: color 0.7s ease-in-out;
    }
    &:hover {
     b{
       color: $primary-blue;
     }
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
}

</style>
