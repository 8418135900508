<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="wrapper">
            <div class="header">
              <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
                <div :class="{ 'show': showContent }" class="header__section-info">
                  <div class="title">MULAG</div>
                </div>
                <transition name="fade">
                  <div v-if="showImage" class="header__section-img"></div>
                </transition>
              </div>
            </div>
            <div class="description" ref="description">
              <b>MULAG</b> offers the full range of equipment for professional roadside maintenance.
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img2"></div>
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img2"></div>
          <div class="img1"></div>
        </div>

        <div class="content">
          <div class="box box__left" :class="{ 'show': showLeft }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Mowing
                </div>
                <div class="container-content__description container-content__description-left">
                  Mowing of grass, undergrowth and shrubbery is one of the main applications of our MULAG power arm
                  attachments. From equipment for verge mowing up to front- or rear-mounted mowers with ranges of up to 8.7
                  m, MULAG offers the suitable working tool for every application.
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.mulag.de/en/roadside-equipment/applications/mowing/" class="btn">View more
                    details </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/saobracajica-mulag/mowing.png" alt="" loading="lazy">

            </div>
         </div>

          <div class="box box__right" :class="{ 'show': showRight }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  Mowing and collecting
                </div>
                <div class="container-content__description container-content__description-right">
                  In particular along motorways, cut grass and shrubbery must be collected to avoid increasing the verge
                  height. To this end, MULAG offers the grass-collecting telescopic arm machines SB 500 and SB 600.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.mulag.de/en/roadside-equipment/applications/mowing-and-collecting/" class="btn">View
                    more details</a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/saobracajica-mulag/mowing-and-collecting.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft2 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Washing
                </div>
                <div class="container-content__description container-content__description-left">
                  Road equipment, traffic signs, walls and tunnels get soiled by exhaust fumes, pollen, and dust. Cleaning
                  them at regular intervals is mandatory to ensure road safety. For these cleaning jobs, MULAG developed
                  special attachments for easy and economically efficient cleaning.
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.mulag.de/en/roadside-equipment/applications/washing/" class="btn">View more
                    details</a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/saobracajica-mulag/washing.png" alt="" loading="lazy">
            </div>
         </div>
          <div class="box box__right" :class="{ 'show': showRight2 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  Tunnel Washing
                </div>
                <div class="container-content__description container-content__description-right">
                  Road tunnels play an increasingly important role in transport policy to divert traffic from inner cities
                  and sensitive environments. Here, clean and bright tunnels are indispensable for a safe traffic flow. As a
                  leading manufacturer and successful market player, MULAG offers different tunnel cleaning equipment for
                  attachment to and installation on Unimogs, trucks and tractors.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.mulag.de/en/tunnel-washing/applications/tunnel-cleaning/" class="btn">View more
                    details</a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/saobracajica-mulag/tunnel-washing.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft3 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Embankment
                </div>
                <div class="container-content__description container-content__description-left">
                  Besides improving the appearance of a road, well-tended verges greatly enhance transport safety. Clean
                  verges allow for smooth draining of rain water and can be used as a hard shoulder in case of emergency.
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.mulag.de/en/roadside-equipment/applications/verge-cleaning/" class="btn">View more
                    details </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/saobracajica-mulag/embankment.png" alt="" loading="lazy">

            </div>
          </div>
          <div class="box box__right" :class="{ 'show': showRight3 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  Ditch cleaning
                </div>
                <div class="container-content__description container-content__description-right">
                  Operative draining ditches are an important component of flood control and must be kept free of foliage,
                  soil, and stones. The MULAG ditch cleaning implement GRG 650 for attachment to the MULAG power arm is
                  tailored to the job. The GRG 650 cleans existing ditches and cuts the ditch floor deeper if required.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.mulag.de/en/roadside-equipment/applications/ditch-cleaning/" class="btn">View more
                    details</a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/saobracajica-mulag/ditch-cleaning.png" alt="" loading="lazy">
            </div>
          </div>

          <div class="box box__left" :class="{ 'show': showLeft4 }">
            <div class="blue-container blue-container__left">
              <div class="container-content">
                <div class="container-content__title">
                  Wood Maintenance
                </div>
                <div class="container-content__description container-content__description-left">
                  In addition to mowing and verge and ditch cleaning, MULAG power arm attachments can also be used for
                  professional wood maintenance. The branch and hedge cutter AWS 2200 cuts branches of up to 110 mm. For
                  cutting branches of a diameter of up to 150 mm, we recommend the MULAG free zone cutter FSG 2000 with four
                  separately driven circular saw blades.
                </div>
                <div class="container-content__pdfs">
                  <a href="https://www.mulag.de/en/roadside-equipment/applications/wood-maintenance/" class="btn">View more
                    details </a>
                </div>
              </div>
              <img class="box-img box-img--left" src="~@/assets/images/saobracajica-mulag/wood-maintenance.png" alt="" loading="lazy">

            </div>
            </div>
          <div class="box box__right" :class="{ 'show': showRight4 }">
            <div class="blue-container blue-container__right">
              <div class="container-content">
                <div class="container-content__title">
                  Water Management
                </div>
                <div class="container-content__description container-content__description-right">
                  Conservation and protection of waters play a major role in improving flood control. The job involves
                  keeping embankments and dams free of excessive growth. As the bank borders are the habitat of many rare
                  animal species, these areas must be treated with special care. The cut grass must be removed to avoid any
                  unintended fertilizing of embankment and water, and to ensure unimpeded draining of flood water.
                </div>
                <div class="container-content__pdfs container-content__pdfs-right">
                  <a href="https://www.mulag.de/en/roadside-equipment/applications/water-management/" class="btn">View more
                    details</a>
                </div>
              </div>
              <img class="box-img box-img--right" src="~@/assets/images/saobracajica-mulag/water-management.png" alt="" loading="lazy">
            </div>
            </div>


          <div class="marquee">
            <div class="marquee-content">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'MulagSaobracaj',
  components: {
    FooterSection,
    Header
  },
  data() {
    return {
      showImage: false,
      showContent: false,
      showLeft: false,
      showRight: false,
      showLeft2: false,
      showRight2: false,
      showLeft3: false,
      showRight3: false,
      showLeft4: false,
      showRight4: false,

      images: [
        require('@/assets/images/top-bg.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/interior-large-logistics-warehouse-ai-generative.png'),
        require('@/assets/images/bottom-bg.png'),
        require('@/assets/images/bottom-bg.png'),
      ],
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  mounted() {
    this.showImage = true;

    setTimeout(() => {
      console.log(this.$refs.description);

      this.$refs.description.classList.add('fade-in');
    }, 500);
    setTimeout(() => {
      this.showContent = true;
    }, 500);

    setTimeout(() => {
      this.showLeft = true;
    }, 1000);
    setTimeout(() => {
      this.showRight = true;
    }, 1600);
    setTimeout(() => {
      this.showLeft2 = true;
    }, 2500);
    setTimeout(() => {
      this.showRight2 = true;
    }, 2800);
    setTimeout(() => {
      this.showLeft3 = true;
    }, 3200);
    setTimeout(() => {
      this.showRight3 = true;
    }, 3300);
    setTimeout(() => {
      this.showLeft4 = true;
    }, 3400);
    setTimeout(() => {
      this.showRight4 = true;
    }, 3350);
  },

}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 900px;

      @include respond(phone) {
        height: 690px;
      }

    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.body-section {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img1 {
      background-image: linear-gradient(to top, rgb(255 255 255), #ffffffb0), linear-gradient(to bottom, rgb(255 255 255), #ffffff42), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 750px;
      /*   @include respond(from-phone-to-tab) {
           height: 650px;
         }*/
      @include respond(lil-phone) {
        height: 1150px !important;
      }
      @include respond(phone) {
        height: 570px;
      }
    }

    .img2 {
      background-image: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 40%)), linear-gradient(to bottom, rgb(255 255 255 / 72%), rgba(255, 255, 255, 0.2588235294)), url('@/assets/images/interior-large-logistics-warehouse-ai-generative.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 820px;
      @include respond(lil-phone) {
        height: 770px;
      }
      @include respond(lil-phone) {
        height: 580px;
      }
      @include respond(tab-land) {
       // height: 1200px;
      }
      @media only screen and (min-width: 1201px) and (max-width: 1499px) {
        height: 880px;
      }
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 830px;
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    @include respond(phone) {
      margin-top: 0;
    }
  }

  .marquee-content {
    display: flex;
    width: calc(100% * 2); /* Double the container size for duplication */
    animation: marquee 20s linear infinite;
  }

  .logo {
    max-width: 150px;
    height: 125px; /* Set a fixed height */
    flex-shrink: 0;
    margin-right: 50px; /* Space between logos */
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    display: flex;

    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      //   align-items: flex-start;
      align-items: center;
      margin-top: 40px;
      @include respond(phone) {
        flex-direction: column;
        margin-top: 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;

        transform: translateY(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 2s ease-out;
        @include respond(phone) {
          text-align: center;
        }

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .title {
          font-size: 100px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          text-align: center;
          @include respond(phone) {
            font-size: 30px !important;
            width: 100%;
          }
        }

      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;
      }

      &-img {
        background-image: url('~@/assets/images/saobracajica-mulag/mulag-saobracajnice-naslovna.png');
        border-radius: 10px;
        width: 850px;
        height: 350px;
        background-size: cover;
        @include respond(phone) {
          display: block !important;
          width: 90vw;
          height: 240px;
          border-radius: 8px;
        }
      }
    }

    .title {
      font-size: 50px;
      font-family: "Montserrat";
      color: $primary-blue;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      @include respond(from-phone-to-tab) {
        font-size: 40px;
      }
    }
  }

  .description {
    max-width: 70vw;
    text-align: center;
    color: $primary-blue;
    font-size: 22px;
    margin-top: 80px;
    font-family: "Karla";
    opacity: 0;
    transition: opacity 3s ease-out;
    @include respond(phone) {
      text-align: justify;
      margin-top: 40px;
      font-size: 20px;
      max-width: 95vw;
    }
    @include respond(lil-phone) {
      margin-top: 20px;
    }
  }

  .description.fade-in {
    opacity: 1;
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
      // display: flex;
      //  flex-direction: column;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .background-image {
    min-height: 40vh;
  }
}
</style>
