<template>
  <div class="wrapper">
    <div class="header">
      <!--      <div class="header__logo-section">
              <div class="info">
                <a href="mailto:office@novisjevermont.me">
                  <div class="info-item">office@novisjevermont.me</div>
                </a>
                <div class="info-item address">
                  <img src="~@/assets/images/location.png" alt="">
                  <a href="mailto:office@novisjevermont.me">
                    <div class="text">
                      Ul. Cetinjska 28A L1-1 br. 7 <br>
                      Podgorica, Crna Gora
                    </div>
                  </a>
                </div>
              </div>
              <div class="logo" @click="navigateTo('/#')">
                <img src="../assets/images/logo.png" alt="">
              </div>
              <div class="info ml-10">
                <div class="info-item address social">
                  <a href="https://www.instagram.com/novi_sjever_mont/"><img src="~@/assets/images/instagram.png" alt=""></a>
                  <a href="https://www.facebook.com/Novi-Sjever-Mont-100834288546477"><img src="~@/assets/images/facebook.png"
                                                                                           alt=""></a>
                  <a href="mailto:office@novisjevermont.me"><img src="~@/assets/images/email.png" alt=""></a>
                </div>
                <a class="info-item number" href="tel:+38268619444">(+382) 68 619 444</a>
              </div>
            </div>
            <div class="header__menu">
              <div class="header__menu-item" @click="scrollTo('about')">O nama</div>
              <div class="header__menu-item">
                Proizvodi
                <div class="dropdown-content">
                  <div class="dropdown-item has-submenu" @click="navigateTo('/elektro-agregati')">
                    DIZEL ELEKTRO AGREGATI &lt;!&ndash;<span class="arrow">&#9656;</span>&ndash;&gt;
                    <div class="submenu">
                      <div class="dropdown-item" @click="navigateTo('/elektroagregati/aksa')">AKSA</div>
                      <div class="dropdown-item" @click="navigateTo('/elektroagregati/teksan')">TEKSAN</div>
                      <div class="dropdown-item" @click="navigateTo('/elektroagregati/kohler')">KOHLER</div>
                    </div>
                  </div>
                  <div class="dropdown-item has-submenu" @click="navigateTo('/avio-oprema')">
                    AVIO OPREMA &lt;!&ndash;<span class="arrow">&#9656;</span>&ndash;&gt;
                    <div class="submenu">
                      <div class="dropdown-item" @click="navigateTo('/avo-oprema')">PUTNICKE STEPENICE</div>
                      <div class="dropdown-item" @click="navigateTo('/avo-oprema')">UTOVARIVAČI ZA TERET</div>
                      &lt;!&ndash;                <div class="dropdown-item" @click="navigateTo('/avo-oprema')">UTOVARIVAČI NA TRANSPORTNOJ TRACI</div>&ndash;&gt;
                      <div class="dropdown-item" @click="navigateTo('/avo-oprema')">PUTNIČKE STEPENICE</div>
                      <div class="dropdown-item" @click="navigateTo('/avo-oprema')">LIČNA VOZILA</div>
                      <div class="dropdown-item" @click="navigateTo('/avo-oprema')">TRANSPORT</div>
                      <div class="dropdown-item" @click="navigateTo('/avo-oprema')">UGOSTITELJSTVO</div>
                      <div class="dropdown-item" @click="navigateTo('/avo-oprema')">JEDINICA ZA SERVIS KUĆA</div>
                      <div class="dropdown-item" @click="navigateTo('/avo-oprema')">JEDINICA VODOVODA</div>
                    </div>
                  </div>
                  <div class="dropdown-item" @click="navigateTo('/liftovi-za-osi')">LIFTOVI ZA OSI</div>
                  <div class="dropdown-item" @click="navigateTo('/elektro-motori')">ELEKTRO MOTORI</div>

                  <div class="dropdown-item" @click="navigateTo('/ups-ovi')">UPS-OVI</div>
                  &lt;!&ndash; Add more categories as needed &ndash;&gt;
                </div>
              </div>
              <div class="header__menu-item" @click="scrollTo('usluge')">Usluge</div>
              <div class="header__menu-item" @click="scrollTo('contact')">Kontakt</div>
            </div>-->
      <div class="header__section pt-lg-5 pt-md-3 pt-sm-2 mt-lg-4">
        <div class="header__section-info" :class="{ 'show': showContent }">
          <div class="subtitle">YOUR NEW</div>
          <div class="title">RELIABLE PARTNER</div>
          <div class="description">
            <!--            Pružamo usluge po modelu total inženjeringa koji
                        podrazumijeva: konsalting, izradu projektne
                        dokumentacije, nabavku opreme, ugradnju i servis.-->

            We provide services under the total engineering model, which includes: consulting, project documentation
            development, equipment procurement, installation, and servicing.

          </div>
          <div class="btn" @click="navigateTo('/about-us')"><!--više o nama-->more about us</div>
        </div>
        <transition name="fade">
          <div v-if="showImage" class="header__section-img"></div>
        </transition>
      </div>
    </div>
    <div class="heading">

    </div>
  </div>


</template>

<script>
export default {
  name: 'HeadingSection',
  data() {
    return {
      showImage: false,
      showContent: false
    };
  },
  props: {
    msg: String
  },
  mounted() {
    this.showImage = true;
    setTimeout(() => {
      this.showContent = true;
    }, 500);
  },
  methods: {
    scrollTo(sectionId) {
      const el = document.getElementById(sectionId);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    }
  }

}
</script>

<style lang="scss">
@import "~@/assets/main.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 40px;

      &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        max-width: 530px;
        width: 100%;
        text-align: left;
        transform: translateX(-100%);
        opacity: 0;
        transition: transform 1.2s ease-out, opacity 3s ease-out;

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        .subtitle {
          font-size: 45px;
          font-weight: bold;
          font-family: "Montserrat";
          text-transform: uppercase;
          color: $light-blue;
          line-height: 1;
          letter-spacing: 1px;
          @include respond(from-phone-to-tab) {
            font-size: 34px;
          }
          @include respond(phone) {
            font-size: 28px;
          }
        }

        .title {
          font-size: 70px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 0.938;
          @include respond(desktop) {
            font-size: 95px;
            font-family: "Montserrat";
            color: rgb(37, 80, 139);
            font-weight: bold;
            text-transform: uppercase;
            line-height: 0.938;

          }
          @include respond(from-phone-to-tab) {
            font-size: 50px;
          }
          @include respond(phone) {
            font-size: 50px;
          }
        }

        .description {
          font-size: 22px;
          font-family: "Karla";
          color: $light-blue;
          line-height: 1;
          padding: 10px 0;
          @include respond(from-phone-to-tab) {
            font-size: 20px;
          }
          @include respond(phone) {
            font-size: 20px;
          }
        }

        .btn {
          font-size: 24px;
          font-family: "Montserrat";
          color: rgb(255, 255, 255);
          font-weight: 600;
          text-transform: uppercase;
          line-height: 1.2;
          background: $primary-blue;
          border-radius: 10px;
          padding: 10px 25px;
          transition: color 0.7s ease, text-shadow 0.3s;
          transform-origin: left center;
          @include respond(from-phone-to-tab) {
            font-size: 16px;
          }
          @include respond(phone) {
            font-size: 16px;
          }

          &:hover {
            background: $light-blue;
            transform: scale(1.02);
            text-shadow: 0px 0px 2px rgba(74, 74, 128, 0.5);
            // color: $primary-blue;
          }
        }
      }

      .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease-in;
      }

      .fade-enter-from, .fade-leave-to {
        opacity: 0;
      }

      .fade-enter-to, .fade-leave-from {
        opacity: 1;
      }

      &-img {
        background-image: url('~@/assets/images/naslovna-hero.png');
       // border-radius: 10px;
        width: 400px;
        height: 450px;
        background-size: cover;
        @include respond(from-phone-to-tab) {
          width: 380px;
        }
        @include respond(phone) {
          display: none;
        }
      }

    }


  }

}

</style>
