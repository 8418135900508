<template>
  <div class="home-page">
    <div class="header">
      <div class="cover-img">
        <div class="img"></div>
        <div class="content">
          <Header></Header>
          <div class="body__advertise">
            <div class="marquee">
              <div class="marquee-content">
                <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
                <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
                <img v-for="logo in partners" :src="logo.src" :alt="logo.alt" :key="logo.id" class="logo" loading="lazy" :style="logo.id === 6 ? { padding: '30px 0', maxWidth: '250px' } : {}">
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="about-us__badges mt-4">
              <div class="info-text">
                We have been in business for over three decades, and due to our commitment to the highest business
                standards, we are recipients of the following certificates:

              </div>
              <ul class="d-flex flex-row justify-content-center" ref="certificatesList">
                <transition-group
                    appear
                    name="list"
                >
                  <li v-for="(certificate, index) in certificates" :key="certificate.name" :data-index="index"
                      class="list-item-hidden">
                    <img style="max-width: 100px" :src="certificate.name" alt="" loading="lazy">
                  </li>
                </transition-group>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="body-section">
      <div class="cover-img">
        <div class="img">
          <div class="img2"></div>
          <div class="img1"></div>
        </div>

        <div class="content">
          <div class="wrapper">
            <div class="header">
              <div class="body__about-us" id="about">
                <div class="count">
                  <div class="container count__item" ref="clientsCounter">
                    <div v-if="visible.clients" class="counter number">
                      {{ currentCount.clients }}
                    </div>
                    <div class="text">CLIENTS </div>
                  </div>
                  <div class="container count__item" ref="projectsCounter">
                    <div v-if="visible.projects" class="counter number">{{ currentCount.projects }}</div>
                    <div class="text">PROJECTS </div>
                  </div>
                </div>
                <div class="about-us">
                  <div class="title">about us</div>
                  <p>
                    With our services and equipment, field service technicians, contracts, as well as trained and certified personnel, along with delivery and installation, we guarantee the safety, loyalty, and reliability of your equipment.
                    <br><br>
                    Upon client request, our service department is available 24 hours a day, all year round.
                    <br><br>
                    We offer clients services such as:
                    <br><br>
                    • Preventive regular maintenance,
                    <br>
                    • Troubleshooting and analysis of mechanical and electrical malfunctions,
                    <br>
                    • 24-hour field service,
                    <br>
                    • Availability of spare parts for various manufacturers,
                    <br>
                    • Trained personnel for operation, maintenance, service, and other services.
                    <br><br>
                    Factory warranty is provided for delivered equipment as well as for installed parts, which is why we are recognized as reliable partners for loyalty, safety, and security.
                  </p>

                </div>
                <div class="images">
                  <img src="~@/assets/images/o-nama1.jpg" alt="" loading="lazy">
                  <img class="img-none" src="~@/assets/images/o-nama2.jpg" alt="" loading="lazy">
                </div>
              </div>
              <div class="catalog-images">
                <ul class="grid-custom" ref="imagesList">
                  <transition-group
                      appear
                      name="list"
                  >
                    <li v-for="(icon, index) in images" :key="icon.name" :data-index="index" class="list-item-hidden">
                      <img :src="icon.name" alt="" loading="lazy">
                    </li>
                  </transition-group>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="cover-img">
        <div class="img">
          <div class="img3"></div>
        </div>
        <div class="content">
          <FooterSection></FooterSection>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FooterSection from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import {onMounted, ref} from "vue";
import gsap from "gsap";

export default {
  name: 'AboutUs',
  components: {
    FooterSection,
    Header
  },
  setup() {
    const images = ref([
      {name: './about-us/1.jpg'},
      {name: './about-us/2.jpg'},
      {name: './about-us/3.jpg'},
      {name: './about-us/4.jpg'},
      {name: './about-us/5.jpg'},
      {name: './about-us/6.jpg'},
      {name: './about-us/7.jpg'},
      //  {name: './about-us/8.jpg'},
      {name: './about-us/9.jpg'},
      {name: './about-us/10.jpg'},
    ]);
    const certificates = ref([
      {name: './img/cisk1.png',},
      {name: './img/cisk2.png'},
      {name: './img/cisk5.png'},
      {name: './img/cisk3.png'},
      {name: './img/cisk4.png'},
      {name: './img/cisk6.png'},
    ]);
    const certificatesList = ref(null);
    const imagesList = ref(null);

    onMounted(() => {
      let isFirstTime = true;
      /*   const observer = new IntersectionObserver((entries) => {
           entries.forEach(entry => {
             if (entry.isIntersecting) {
               entry.target.querySelectorAll('li').forEach((el, index) => {
                 el.classList.remove('list-item-hidden');
                   gsap.fromTo(el, {
                     opacity: 0,
                     translateY: 100,
                     visibility: 'hidden'
                   }, {
                     opacity: 1,
                     translateY: 0,
                     visibility: 'visible',
                     duration: 1.2,
                     delay: index * 0.2
                   });

               });
                 observer.unobserve(entry.target);
             }
           });
         }, {
           threshold: 0.5
         });*/
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && isFirstTime) {

            const isImagesList = entry.target === imagesList.value;
            // this.visibleCategory = true;
            entry.target.querySelectorAll('li').forEach((el, index) => {
              el.classList.remove('list-item-hidden');
              if (isImagesList) {
                isFirstTime = false;
                gsap.fromTo(el, {
                  opacity: 0,
                  translateY: 300,
                  visibility: 'hidden'
                }, {
                  opacity: 1,
                  translateY: 0,
                  visibility: 'visible',
                  duration: 1.2,
                  delay: index * 0.2
                });
              } else {
                gsap.fromTo(el, {
                  opacity: 0,
                  translateY: 100,
                  visibility: 'hidden'
                }, {
                  opacity: 1,
                  translateY: 0,
                  visibility: 'visible',
                  duration: 1.2,
                  delay: index * 0.2
                });
              }
            });
            //  observer.unobserve(entry.target);
          }
        });
      }, {
        threshold: 0.5
      });
      observer.observe(imagesList.value);
      observer.observe(certificatesList.value);
    });

    return {images, imagesList, certificates, certificatesList};
  },
  data() {
    return {
      currentCount: {
        clients: 0,
        projects: 0
      },
      targetCount: {
        clients: 415,
        projects: 1140
      },
      visible: {
        clients: false,
        projects: false
      },
      interval: {
        clients: null,
        projects: null
      },
      partners: [
        {id: 1, src: './partners/aksa.png', alt: 'Logo 1'},
        {id: 2, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 3, src: './partners/Aviogei.png', alt: 'Logo 3'},
        {id: 4, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 5, src: './partners/cummins.png', alt: 'Logo 5'},
        {id: 6, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 7, src: './partners/dinobus.svg', alt: 'Logo 3'},
        {id: 8, src: './partners/fg-wilson.png', alt: 'Logo 8'},
        {id: 9, src: './partners/green-power.png', alt: 'Logo 9'},
        {id: 10, src: './partners/kohler-sdmo.png', alt: 'Logo 10'},
        {id: 11, src: './partners/mtu.png', alt: 'Logo 11'},
        {id: 12, src: './partners/prekings.png', alt: 'Logo 12'},
        {id: 13, src: './partners/teksan.png', alt: 'Logo 13'},
        {id: 14, src: './partners/boschung-logo.png', alt: 'Logo 3'},
        {id: 15, src: './partners/visa.png', alt: 'Logo 14'},
        {id: 16, src: './partners/mulag-logo.png', alt: 'Logo 3'},
        {id: 17, src: './partners/bertoli.png', alt: 'Logo 14'},
        {id: 18, src: './partners/emsa.png', alt: 'Logo 7'},
        {id: 19, src: './partners/boschung-logo.png', alt: 'Logo 3'},
      ]
    };
  },
  methods: {
    countUp(counterKey) {
      this.interval[counterKey] = setInterval(() => {
        if (this.currentCount[counterKey] < this.targetCount[counterKey]) {
          this.currentCount[counterKey] += 10; // Increment by 10
          if (this.currentCount[counterKey] > this.targetCount[counterKey]) {
            this.currentCount[counterKey] = this.targetCount[counterKey];
          }
        } else {
          clearInterval(this.interval[counterKey]);
        }
      }, 10);
    },
    setupObserver(element, counterKey) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.visible[counterKey] = true;
            this.countUp(counterKey);
            observer.unobserve(entry.target);
          }
        });
      }, {threshold: 0.5});

      observer.observe(element);
    }
  },
  mounted() {
    this.setupObserver(this.$refs.clientsCounter, 'clients');
    this.setupObserver(this.$refs.projectsCounter, 'projects');
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.header {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 85%), url('@/assets/images/top-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 700px;
      }
      @include respond(phone) {
        height: 650px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;

      .body {
        &__advertise {
          width: 100vw;
          margin: 50px 0;
          @include respond(from-phone-to-tab) {
            margin: 50px 0;
          }
          @include respond(phone) {
            margin: 10px 0;
          }

          .marquee {
            width: 100%;
            overflow: hidden;
            position: relative;
          }

          .marquee-content {
            display: flex;
            width: calc(100% * 2); /* Double the container size for duplication */
            animation: marquee 20s linear infinite;
          }

          .logo {
            max-width: 200px;
            height: 125px;
            flex-shrink: 0;
            margin-right: 50px;

            @include respond(from-phone-to-tab) {
              height: 100px;
            }
          }

          @keyframes marquee {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-50%); /* Move by half of the total width */
            }
          }
        }

      }

      .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .about-us {
          width: 70vw;
          @include respond(tab-land) {
            width: 95vw;
          }
          @media only screen and (min-width: 1201px) and (max-width: 1499px) {
            width: 95vw;
          }

          &__badges {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            max-width: 700px;

            @include respond(phone) {
              max-width: 400px;
              ul {
                padding-left: 0px;
              }
              li {
                padding: 0px 7px !important;
              }
              img {
                width: 60px;
              }
            }
            @include respond(lil-phone) {

              img {
                width: 45px;
              }
            }
            @include respond(from-phone-to-tab) {
              li {
                padding: 0px 10px !important;
              }
              img {
                width: 90px;
              }
            }

            .info-text {
              font-size: 18px;
              font-family: "Karla";
              color: $primary-blue;
              line-height: 1.2;
              text-align: center;

              @include respond(from-phone-to-tab) {
                padding: 0 60px;
                font-size: 16px;
              }
            }

            li {
              list-style-type: none;
              padding: 0 20px;
            }

          }

          .list-item-hidden {
            opacity: 0;
            transform: translateX(300px); /* For icons */
            visibility: hidden; /* Ensures content is not accidentally interactable */
          }
        }
      }
    }
  }
}

.body-section {
  .cover-img {
    .img {
      .img2 {
        @include respond(phone) {
          height: 1200px !important;
        }
      }
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 70vw;
    @include respond(tab-land) {
      width: 95vw;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1499px) {
      width: 95vw;
    }

    .body {
      &__about-us {
        font-family: "Montserrat";
        display: flex;
        align-items: center;
        width: 100%;
        @include respond(from-phone-to-tab) {
          flex-direction: column-reverse;
          img {
            border-radius: 10px;
          }
        }
        @include respond(phone) {
          flex-direction: column-reverse;
          img {
            border-radius: 4px;
          }
          .img-none {
            display: none;
          }
        }

        .count {
          background: $light-blue;
          padding: 45px 20px;
          border-radius: 10px 0 0 10px;
          font-weight: bold;
          line-height: 1.2;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 85px;
          @include respond(from-phone-to-tab) {
            flex-direction: row;
            border-radius: 0 0 8px 8px;
          }
          @include respond(phone) {
            flex-direction: row;
            border-radius: 4px 4px 0 0;
          }

          .number {
            font-size: 46px;
            color: white;
            @include respond(phone) {
              font-size: 35px;
            }
          }

          .text {
            font-size: 21.79px;
            text-transform: uppercase;
            color: $primary-blue;
            @include respond(phone) {
              font-size: 20px;
            }
          }
        }

        .about-us {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          color: white;
          background: $primary-blue;
          line-height: 1.2;
          text-align: left;
          padding: 50px 30px;
          border-radius: 10px;
          min-height: 550px;

          @include respond(phone) {
            padding: 25px;
          }

          .title {
            font-size: 44px;
            font-family: "Montserrat";
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 2rem;
            @include respond(phone) {
              font-size: 40px
            }
          }

          p {
            font-size: 23px;
            font-family: "Karla";
            text-align: left;
            @include respond(phone) {
              font-size: 20px !important;
            }
          }

          @include respond(from-phone-to-tab) {
            min-height: 430px;
            .title {
              font-size: 40px;
            }
            p {
              font-size: 20px;
            }
          }

        }

        .images {
          display: flex;
          flex-direction: column;
          @include respond(from-phone-to-tab) {
            flex-direction: row;
          }

          img {
            width: 350px
          }
        }
      }
    }

    .catalog-images {
      @include respond(from-phone-to-tab) {
        margin-left: -30px;
      }
      @include respond(phone) {
        margin-left: -30px;
      }

      img {
        width: 350px;
        position: relative;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        @include respond(from-phone-to-tab) {
          max-width: 250px;
        }
        @include respond(phone) {
          max-width: 200px;
          height: 150px;
        }
      }

      h1 {
        color: white;
        font-size: 40px;
        font-weight: 600;
        // width: 220px;
        line-height: 1;
        text-align: end;
        padding-right: 10px;
        @include respond(from-phone-to-tab) {
          font-size: 30px;
        }
        @include respond(phone) {
          font-size: 20px;
        }
      }

      .title {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: end;
      }

      li {
        list-style-type: none;
        border-radius: 10px;
        cursor: pointer;
        line-height: 1.5em;
      }

      .grid-custom {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
        //grid-template-columns: 1fr 1fr !important;
        grid-gap: 20px !important;
        margin: 40px auto !important;
        @include respond(phone) {
          grid-gap: 10px !important;
        }
      }
    }

    /*
        &__section {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-start;
          align-items: center;
          margin-top: 40px;

          &-info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-width: 530px;
            width: 100%;
            text-align: left;

            transform: translateY(-100%);
            opacity: 0;
            transition: transform 1.2s ease-out, opacity 2s ease-out;

            &.show {
              transform: translateX(0);
              opacity: 1;
            }

            .title {
              font-size: 100px;
              font-family: "Montserrat";
              color: $primary-blue;
              font-weight: bold;
              text-transform: uppercase;
              line-height: 0.938;
              text-align: center;
            }

          }

          .fade-enter-active, .fade-leave-active {
            transition: opacity 1s ease-in;
          }

          .fade-enter-from, .fade-leave-to {
            opacity: 0;
          }

          .fade-enter-to, .fade-leave-from {
            opacity: 1;
          }

          &-img {
            background-image: url('~@/assets/images/kohler.png');
            border-radius: 10px;
            width: 500px;
            height: 300px;
            background-size: cover;

          }
        }

        .title {
          font-size: 50px;
          font-family: "Montserrat";
          color: $primary-blue;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 1.3;

        }*/
  }
}

.footer {
  position: relative;
  overflow: hidden;

  .cover-img {
    .img {
      position: relative;
    }

    .img3 {
      background-image: linear-gradient(to top, rgba(88, 135, 198, 0.74), #5887C6BD), linear-gradient(to bottom, rgb(88, 135, 198, 0.74), #5887C6BD), url('@/assets/images/bottom-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      // position: relative;
      width: 100%;
      height: 950px;
      @include respond(from-phone-to-tab) {
        height: 620px;
      }
      @include respond(phone) {
        height: 630px;
      }
    }

    img {
      position: relative;
      top: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .background-image {
    min-height: 40vh;
  }
}

</style>
